import { useEffect, useRef, useState } from 'react';
import {
  CheckIcon,
  ChevronDownIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import { Combobox } from '@headlessui/react';
import { colorsList } from '../constants/colors';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

type Props = {
  heading?: string;
  items: { [key: string]: any }[];
  selected: { [key: string]: any };
  type: string;
  defaultValue?: string;
  placeholder?: string;
  onChange: (item: any) => void;
  disabled?: boolean;
  noRecordText?: string;
};

export default function TableMultiSelect(props: Props) {
  const [query, setQuery] = useState('');
  const [selected, setSelected] = useState(props.selected);
  const [position, setPosition] = useState({ posX: 0, posY: 0 });
  const comboBtn = useRef<HTMLButtonElement | null>(null);
  const comboInput = useRef<HTMLInputElement | null>(null);
  const options = props.items;
  let selectedColor: any;
  if (props.type === 'colors') {
    selectedColor = colorsList?.find(
      (color: any) => color.name === props?.selected?.name,
    );
  } else if (props.type === 'task-status') {
    selectedColor = colorsList?.find(
      (color: any) => color.name === props?.selected?.color,
    );
  }

  /* const selectedColor = colorsList?.find(
    (color: any) => color.name === props.selected?.color
  ); */

  const handleInputClick = () => comboBtn.current?.click();

  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);

  const onChange = (item: any) => {
    setSelected(props.selected);
    props.onChange(item);
  };

  const filteredoptions =
    query === ''
      ? options
      : options.filter((option) => {
          return option.name.toLowerCase().includes(query.toLowerCase());
        });

  function setPositionOfOptions() {
    const posX = comboInput.current?.getBoundingClientRect().left || 0;
    const posY = comboInput.current?.getBoundingClientRect().bottom || 0;
    setPosition({ posX, posY });
  }

  return (
    <Combobox
      as='div'
      value={selected?.name}
      onChange={onChange}
      disabled={props.disabled}
    >
      {({ open }) => {
        return (
          <>
            {open && (
              <div className='fixed left-0 md:left-64 top-0 w-full h-full bg-transparent'></div>
            )}
            <Combobox.Label className='block text-sm font-medium text-gray-700'>
              {props.heading}
            </Combobox.Label>
            <div className={`relative ${props.heading && 'mt-1'}`}>
              <Combobox.Input
                ref={comboInput}
                style={{
                  backgroundColor:
                    props.type === 'task-status'
                      ? selectedColor?.value
                      : 'white',
                  color:
                    props.type === 'task-status' &&
                    selectedColor?.name.includes('Dark')
                      ? 'white'
                      : 'black',
                }}
                className={`combo w-full rounded-md border border-gray-300 bg-white py-2 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm capitalize ${
                  props.type === 'sort' ? 'pl-8' : 'pl-3'
                } ${props.disabled ? 'cursor-not-allowed' : ''} `}
                onChange={(event: any) => {
                  setQuery(event.target.value);
                }}
                displayValue={props.selected?.name}
                placeholder={props.placeholder}
                onClick={handleInputClick}
                onFocus={(e: any) => {
                  setQuery('');
                  return e.target.select();
                }}
              />
              <Combobox.Button
                ref={comboBtn}
                onClick={setPositionOfOptions}
                className='absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'
              >
                {({ open }) =>
                  open ? (
                    <XMarkIcon
                      className={`h-5 w-5 ${
                        props.type === 'task-status' &&
                        selectedColor?.name.includes('Light')
                          ? 'text-gray-500'
                          : 'text-gray-400'
                      } ${props.type !== 'task-status' ? 'text-gray-400' : ''}`}
                      aria-hidden='true'
                    />
                  ) : (
                    <ChevronDownIcon
                      className={`h-5 w-5 ${
                        props.type === 'task-status' &&
                        selectedColor?.name.includes('Light')
                          ? 'text-gray-500'
                          : 'text-gray-400'
                      } ${props.type !== 'task-status' ? 'text-gray-400' : ''}`}
                      aria-hidden='true'
                    />
                  )
                }
              </Combobox.Button>

              {filteredoptions?.length === 0 ? (
                <Combobox.Options
                  style={{
                    position: 'fixed',
                    zIndex: 100,
                    top: `${position.posY}px`,
                    left: `${position.posX}px`,
                    marginTop: '0.5rem',
                    marginRight: '-0.5rem',
                    width:
                      comboInput.current?.clientWidth ||
                      comboInput.current?.offsetWidth,
                  }}
                  className='mt-1 rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm'
                >
                  <Combobox.Option
                    disabled={true}
                    value={''}
                    className='relative cursor-default text-center whitespace-normal select-none py-2 text-xs text-gray-900 sm:font-bold'
                  >
                    {props.noRecordText ?? 'No Record Available'}
                  </Combobox.Option>
                </Combobox.Options>
              ) : (
                <Combobox.Options
                  style={{
                    position: 'fixed',
                    zIndex: 100,
                    top: `${position.posY}px`,
                    left: `${position.posX}px`,
                    marginTop: '0.5rem',
                    marginRight: '-0.5rem',
                    width:
                      comboInput.current?.clientWidth ||
                      comboInput.current?.offsetWidth,
                  }}
                  className='max-h-40 mt-1 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm vertical-scroll'
                >
                  {filteredoptions?.map((option) => (
                    <Combobox.Option
                      key={option._id}
                      value={option}
                      className={({ active }) =>
                        classNames(
                          'relative cursor-default select-none py-2 pl-3 pr-9',
                          active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        )
                      }
                    >
                      {({ active, selected }) => (
                        <>
                          <div className='flex items-center'>
                            <span
                              className={classNames(
                                'block truncate capitalize',
                                selected && 'font-semibold',
                              )}
                            >
                              {option.name}
                              {option.name === props.selected?.name ? (
                                <span
                                  className={classNames(
                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                    active ? 'text-white' : 'text-indigo-600',
                                  )}
                                >
                                  <CheckIcon
                                    className='h-5 w-5'
                                    aria-hidden='true'
                                  />
                                </span>
                              ) : (
                                ''
                              )}
                            </span>
                          </div>

                          {selected && (
                            <span
                              className={classNames(
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                active ? 'text-white' : 'text-indigo-600',
                              )}
                            >
                              <CheckIcon
                                className='h-5 w-5'
                                aria-hidden='true'
                              />
                            </span>
                          )}
                        </>
                      )}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              )}
            </div>
          </>
        );
      }}
    </Combobox>
  );
}
