import { createSlice } from '@reduxjs/toolkit';

const initialState: { [key: string]: any } = {};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    updateCommon: (state, action) => {
      Object.assign(state, action.payload);
    },
  },
});

export const { updateCommon } = commonSlice.actions;
export default commonSlice.reducer;
