import { Combobox } from '@headlessui/react';
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { useRef, useState } from 'react';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

type Props = {
  heading?: string;
  items: { [key: string]: any }[];
  selected: { [key: string]: any };
  type: string;
  defaultValue?: string;
  placeholder?: string;
  onChange: (item: any) => void;
  disabled?: boolean;
  allTaskCount?: number;
  gstTaskCount?: number;
  itTaskCount?: number;
  otherTaskCount?: number;
};

export default function MultiSelectCheckbox(props: Props) {
  const [query, setQuery] = useState('');
  const comboBtn = useRef<HTMLButtonElement | null>(null);
  const options = props.items;

  const handleInputClick = () => comboBtn.current?.click();

  const onChange = (item: any) => {
    props.onChange(item[0]);
  };

  const filteredoptions =
    query === ''
      ? options
      : options.filter((option) => {
          return option.name.toLowerCase().includes(query.toLowerCase());
        });

  const displayCheckedValue = () => {
    if (props.type === 'Tasks') {
      const selected = props.selected;
      const { allTaskCount, gstTaskCount, itTaskCount, otherTaskCount } = props;

      const allSelectedTaskCount = props.selected.length;
      const allSelectedGstTaskCount = props.selected.filter(
        (item: any) => item.type === 'GST',
      ).length;
      const allSelectedItTaskCount = props.selected.filter(
        (item: any) => item.type === 'IT',
      ).length;
      const allSelectedOtherTaskCount = props.selected.filter(
        (item: any) => item.type === 'OTHER',
      ).length;

      if (allSelectedTaskCount === allTaskCount) {
        return 'All';
      }
      if (
        allSelectedGstTaskCount === gstTaskCount &&
        allSelectedItTaskCount === itTaskCount &&
        allSelectedOtherTaskCount === otherTaskCount
      ) {
        return selected
          .reduce((acc: any, item: any) => {
            if (
              item.name === 'All GST Tasks' ||
              item.name === 'All IT Tasks' ||
              item.name === 'All Other Tasks'
            ) {
              acc.push(item);
            }
            return acc;
          }, [])
          .map((item: any) => item.name)
          .join(', ');
      }
      if (
        allSelectedGstTaskCount === gstTaskCount &&
        allSelectedItTaskCount === itTaskCount
      ) {
        return selected
          .reduce((acc: any, item: any) => {
            if (item.name === 'All GST Tasks' || item.name === 'All IT Tasks') {
              acc.push(item);
            }
            return acc;
          }, [])
          .map((item: any) => item.name)
          .join(', ');
      }
      if (
        allSelectedItTaskCount === itTaskCount &&
        allSelectedOtherTaskCount === otherTaskCount
      ) {
        return selected
          .reduce((acc: any, item: any) => {
            if (
              item.name === 'All IT Tasks' ||
              item.name === 'All Other Tasks'
            ) {
              acc.push(item);
            }
            return acc;
          }, [])
          .map((item: any) => item.name)
          .join(', ');
      }
      if (
        allSelectedGstTaskCount === gstTaskCount &&
        allSelectedOtherTaskCount === otherTaskCount
      ) {
        return selected
          .reduce((acc: any, item: any) => {
            if (
              item.name === 'All GST Tasks' ||
              item.name === 'All Other Tasks'
            ) {
              acc.push(item);
            }
            return acc;
          }, [])
          .map((item: any) => item.name)
          .join(', ');
      }
      if (allSelectedGstTaskCount === gstTaskCount) {
        return selected
          .reduce((acc: any, item: any) => {
            if (item.name === 'All GST Tasks' || item.type !== 'GST') {
              acc.push(item);
            }
            return acc;
          }, [])
          .map((item: any) => item.name)
          .join(', ');
      }
      if (allSelectedItTaskCount === itTaskCount) {
        return selected
          .reduce((acc: any, item: any) => {
            if (item.name === 'All IT Tasks' || item.type !== 'IT') {
              acc.push(item);
            }
            return acc;
          }, [])
          .map((item: any) => item.name)
          .join(', ');
      }
      if (allSelectedOtherTaskCount === otherTaskCount) {
        return selected
          .reduce((acc: any, item: any) => {
            if (item.name === 'All Other Tasks' || item.type !== 'OTHER') {
              acc.push(item);
            }
            return acc;
          }, [])
          .map((item: any) => item.name)
          .join(', ');
      }
      return props.selected.map((item: any) => item.name).join(', ');
    } else if (
      props.type === 'message-status-filter' ||
      props.type === 'user-filter' ||
      props.type === 'client-filter' ||
      props.type === 'report-for-filter' ||
      props.type === 'change-type-filter' ||
      props.type === 'bill-type-filter' ||
      props.type === 'task-status-type-filter'
    ) {
      const { selected, items } = props;

      if (selected.length === items.length) {
        return 'All';
      }
      return props.selected.map((item: any) => item.name).join(', ');
    } else {
      return props.selected.map((item: any) => item.name).join(', ');
    }
  };

  return (
    <Combobox
      as='div'
      value={[]}
      onChange={onChange}
      disabled={props.disabled}
      multiple
    >
      <Combobox.Label className='block text-sm font-medium text-gray-700'>
        {props.heading}
      </Combobox.Label>
      <div className={`relative ${props.heading && 'mt-1'}`}>
        <Combobox.Input
          onClick={handleInputClick}
          className={`w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm ${
            props.disabled ? 'cursor-not-allowed' : ''
          }`}
          onChange={(event) => {
            setQuery(event.target.value);
          }}
          displayValue={() => displayCheckedValue()}
          placeholder={props.placeholder}
          onFocus={(e: any) => {
            setQuery('');
            return e.target.select();
          }}
          autoComplete='off'
        />
        <Combobox.Button
          ref={comboBtn}
          className='absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'
        >
          {({ open }) =>
            open ? (
              <XMarkIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
            ) : (
              <ChevronDownIcon
                className='h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
            )
          }
        </Combobox.Button>

        {filteredoptions?.length === 0 ? (
          <Combobox.Options className='max-h-40 absolute z-10 mt-1 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
            <Combobox.Option
              disabled={true}
              value={''}
              className='relative cursor-default select-none py-2 pl-3 pr-9 text-sm text-gray-900 sm:font-bold'
            >
              No Record Available
            </Combobox.Option>
          </Combobox.Options>
        ) : (
          <Combobox.Options className='vertical-scroll max-h-40 absolute z-10 mt-1 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
            {filteredoptions?.map((option) => (
              <Combobox.Option
                key={option._id}
                value={option}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                    props.type === 'Tasks' &&
                      option.name !== 'All' &&
                      (option.name === 'All IT Tasks' ||
                      option.name === 'All GST Tasks' ||
                      option.name === 'All Other Tasks'
                        ? 'pl-6'
                        : 'pl-12'),
                  )
                }
              >
                <>
                  <div className='flex items-center'>
                    <span>
                      <input
                        id={'checkbox'}
                        name={'checkbox'}
                        type='checkbox'
                        style={{ border: 'solid 1px #d1d5db' }}
                        className='focus:ring-indigo-500 mr-3 h-4 w-4 text-indigo-600 block rounded  items-center border-gray-400'
                        checked={
                          props.selected.findIndex(
                            (item: any) => item._id === option._id,
                          ) !== -1
                        }
                        onChange={() => null}
                      />
                    </span>

                    <span className='block truncate'>{option.name}</span>
                  </div>
                </>
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
