import agent from '../../agent';
import { checkToken, login, logout } from '../reducers/userAuth';

const authMiddleware = () => (next: any) => (action: any) => {
  if (action.type === login.type && !action.error) {
    agent.setToken(action.payload);
    document.cookie = `token=${action.payload}; expires=${new Date(
      Date.now() + 31536000000,
    )}; path=/;`;
  } else if (action.type === logout.type) {
    agent.setToken(undefined);
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  } else if (action.type === checkToken.type && action.error) {
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }
  next(action);
};

export default authMiddleware;
