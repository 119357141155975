export function updateStatusInCommonState(status: []) {
  const tasks: string[] = [];
  status.forEach((item: any) => tasks.push(...item.tasks));
  const uniqueTasks = Array.from(new Set(tasks));
  const taskWiseStatus: any = {};
  uniqueTasks.forEach((name: string) => {
    const list = status.filter(
      (item: any) => item.tasks.includes(name) || item.tasks.length === 0,
    );
    taskWiseStatus[name] = list;
  });
  const statusApplicableToAllTasks = status.filter(
    (item: any) => item.tasks.length === 0,
    // tasks.length === 0 means status applicable for all task
  );
  return {
    status,
    statusApplicableToAllTasks,
    taskStatus: taskWiseStatus,
  };
}
