import { ChevronDownIcon } from '@heroicons/react/20/solid';
import axios from 'axios';
import {
  MutableRefObject,
  RefObject,
  createRef,
  useEffect,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import agent from '../agent';
import { updateMaintenanceMode } from '../store/reducers';
import { updateMaintenanceMessage } from '../store/reducers/appConfig';
import Icon from './Icon';

interface FrontendVersion {
  frontendVersion: number;
  maintenance: boolean;
  maintenanceMessage: string;
  IPList: string[];
}

interface Props {
  showInfoFooter: boolean;
  showHideInfoFooter: () => void;
  infoFooterHeight: number;
}

interface IState {
  showMaintenaceMessage: boolean;
  showRefreshMessage: boolean;
  frontendVersion?: FrontendVersion['frontendVersion'];
  showMaintenance: boolean;
  maintenanceMessage?: string;
}

export default function MessageBar(props: Props) {
  const dispatch = useDispatch();

  const [state, setState] = useState<IState>({
    showMaintenaceMessage: false,
    showRefreshMessage: false,
    showMaintenance: false,
    maintenanceMessage: '',
  });

  const getFrontEndVersionIntervalRef: MutableRefObject<NodeJS.Timeout | null> =
    createRef();
  const toggleInfoButtonRef: RefObject<HTMLDivElement> = createRef();

  useEffect(() => {
    const frontendJSVersion = localStorage.getItem('frontendJSVersion');

    if (frontendJSVersion) {
      setState((prevState: any) => ({
        ...prevState,
        frontendVersion: JSON.parse(
          localStorage.getItem('frontendJSVersion') || '',
        ),
      }));
    }

    checkMaintenance(); // intial check for maintenance mode
    getFrontEndVersionIntervalRef.current = setInterval(
      () => {
        getFrontEndVersion();
      },
      1000 * 60 * 15,
    );

    return () => {
      if (getFrontEndVersionIntervalRef.current) {
        clearInterval(getFrontEndVersionIntervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (state.frontendVersion) {
      const prevFrontendJSVersion = localStorage.getItem('frontendJSVersion');

      if (!prevFrontendJSVersion) {
        localStorage.setItem(
          'frontendJSVersion',
          JSON.stringify(state.frontendVersion),
        );

        return;
      }

      if (prevFrontendJSVersion === state.frontendVersion.toString()) {
        return;
      }

      localStorage.setItem(
        'frontendJSVersion',
        JSON.stringify(state.frontendVersion),
      );

      setState((prevState: any) => ({
        ...prevState,
        showRefreshMessage: true,
      }));
    }
  }, [state.frontendVersion]);

  function checkMaintenance() {
    agent.FrontendVersion.getFrontendVersion().then(
      (response: FrontendVersion) => {
        dispatch(updateMaintenanceMessage(response.maintenanceMessage));

        if (response.maintenance) {
          getIp(response.maintenance, response.IPList);
        } else {
          setState((state) => ({ ...state, showMaintenance: false }));
          dispatch(updateMaintenanceMode(false));
        }
      },
    );
  }

  function getFrontEndVersion() {
    agent.FrontendVersion.getFrontendVersion().then(
      (response: FrontendVersion) => {
        setState((state) => ({
          ...state,
          frontendVersion: response.frontendVersion,
        }));

        dispatch(updateMaintenanceMessage(response.maintenanceMessage));

        if (response.maintenance) {
          getIp(response.maintenance, response.IPList);
        } else {
          setState((state) => ({ ...state, showMaintenance: false }));
          dispatch(updateMaintenanceMode(false));
        }
      },
    );
  }

  const getIp = (maintenance: boolean, ipList: string[]) => {
    axios
      .get<{ ip: string }>('https://api.ipify.org/?format=json')
      .then((res: any) => {
        const isUserIpAllowed = ipList.includes(res.data.ip);

        if (maintenance && isUserIpAllowed) {
          setState((state) => ({ ...state, showMaintenance: false }));
          dispatch(updateMaintenanceMode(false));
        } else {
          setState((state) => ({ ...state, showMaintenance: true }));
          dispatch(updateMaintenanceMode(true));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function refreshPage() {
    window.location.reload();
    localStorage.setItem('frontendJSVersion', '');
  }

  const { showInfoFooter, showHideInfoFooter, infoFooterHeight } = props;
  const { showMaintenaceMessage, showRefreshMessage } = state;

  return (
    <>
      {(showMaintenaceMessage || showRefreshMessage) && (
        <div
          ref={toggleInfoButtonRef}
          style={{
            bottom: showInfoFooter
              ? `calc(${infoFooterHeight}px +  ${
                  showRefreshMessage && showMaintenaceMessage
                    ? '8rem'
                    : showRefreshMessage
                      ? '4rem'
                      : '3rem'
                })`
              : '0',
          }}
          className='md:hidden absolute z-0 left-6 border-2 border-red-500 rounded-t-md bg-red-400 cursor-pointer transition-[bottom] duration-300 ease-in-out'
          onClick={showHideInfoFooter}
        >
          <ChevronDownIcon
            className={`h-8 w-8 transition-transform duration-300 ease-in-out ${
              showInfoFooter ? '' : 'rotate-180'
            }`}
          />
        </div>
      )}
      <div
        className={`space-y-3 divide-y fixed bottom-0 inset-x-0 z-10 inline-block w-full text-center bg-red-400 text-white text-sm font-bold transition-transform origin-bottom duration-300 ease-in-out ${
          showInfoFooter ? '' : 'scale-y-0'
        }`}
      >
        {showRefreshMessage && (
          <button
            onClick={() => refreshPage()}
            className={`pt-2 ${
              showRefreshMessage && !showMaintenaceMessage ? 'pb-2' : ''
            }`}
          >
            <p>
              Application is updated to a new version. Please refresh the page
              for the latest updates.
              <span className='bg-white text-black font-medium ml-10 my-1 rounded-lg px-2 py-1 inline-flex gap-2 items-center'>
                Refresh
                <Icon name='refresh' className='h-4 w-4 text-inherit' />
              </span>
            </p>
          </button>
        )}
        {showMaintenaceMessage && (
          <p className='py-2'>
            The system will remain in maintenance mode from 03:00 PM to 03:30 PM
            on 06th May 2023. Inconvenience Regretted.
          </p>
        )}
      </div>
    </>
  );
}
