export default function timeTillMidnight(date: string | number | Date) {
  return new Date(
    new Date(date).toISOString().split('T')[0] + 'T23:59:59.999Z',
  );
}

export function timeFromMidnight(date: string | number | Date) {
  return new Date(
    new Date(date).toISOString().split('T')[0] + 'T00:00:00.000Z',
  );
}
