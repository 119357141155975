import { Link } from 'react-router-dom';
import Icon from './Icon';

interface Props {
  name: string;
  icon?: any; // HeroIcon component name or Icon component name
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  type?: 'button' | 'link';
  to?: string;
  iconOnly?: boolean;
  isLoading?: boolean;
  noMr?: boolean;
}

export default function Button(props: Props) {
  const buttonClass =
    'relative inline-flex items-center gap-x-2 pl-4 pr-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:bg-indigo-700 focus:outline-none disabled:bg-indigo-500 disabled:cursor-not-allowed';

  return props.type === 'link' ? (
    <Link
      to={props.to!}
      className={props.className || buttonClass}
      onClick={props.onClick}
    >
      {typeof props.icon === 'object' ? (
        <props.icon
          className={`${props.noMr ? '' : 'mr-2'} h-4 w-4`}
          aria-hidden='true'
        />
      ) : typeof props.icon === 'string' ? (
        <Icon
          name={props.icon}
          className={`${props.noMr ? '' : 'mr-2'} h-4 w-4`}
          aria-hidden='true'
        />
      ) : null}
      {props.name}
    </Link>
  ) : (
    <button
      type='button'
      className={props.className || buttonClass}
      disabled={props.disabled || false}
      onClick={props.onClick}
    >
      {props.isLoading ? (
        <Icon name='loading' className={`${props.noMr ? '' : 'mr-2'}`} />
      ) : (
        <></>
      )}
      <span className={`${props.iconOnly || props.noMr ? '' : ''}`}>
        {typeof props.icon === 'object' ? (
          <props.icon className='h-4 w-4' aria-hidden='true' />
        ) : typeof props.icon === 'string' ? (
          <Icon name={props.icon} className='h-4 w-4' aria-hidden='true' />
        ) : (
          <></>
        )}
      </span>
      {props.name}
      {props.iconOnly && <></>}
    </button>
  );
}
