import agent from '../agent';
import { adminRights } from '../constants/defaultUserRights';

export const getUserRights = (
  workSpaceId: string,
  updateCommon: any,
  onNotify: any,
) => {
  if (workSpaceId) {
    agent.User.getUserRights(workSpaceId)
      .then((response: any) => {
        // eslint-disable-next-line no-prototype-builtins
        if (response?.hasOwnProperty('allRights') && response.allRights) {
          updateCommon({ rights: adminRights });
        } else {
          updateCommon({ rights: response.rights });
        }
      })
      .catch((err: any) => {
        onNotify({
          title: 'Could not fetch user rights',
          message: err?.response?.data?.message || err?.message || err,
          type: 'danger',
        });
      });
  }
};

export const getCookie = (cname: string) => {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export function isMongoObjectId(str: string) {
  const objectIdPattern = /^[0-9a-fA-F]{24}$/;
  return objectIdPattern.test(str);
}

export function bytesToHumanReadableString(size: number) {
  const i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return (
    Number((size / Math.pow(1024, i)).toFixed(2)) * 1 +
    ' ' +
    ['B', 'kB', 'MB', 'GB', 'TB'][i]
  );
}

export async function downloadURI(url: string, filename: string) {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // Revoking the object URL to free up memory
    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error('Error downloading file:', error);
  }
}

export function loadScript(src: string) {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}
