import { Dialog, Menu, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { PlayIcon, StopIcon } from '@heroicons/react/24/outline';
import { Fragment, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import agent from '../../agent';
import Button from '../../components/Button';
import CancleModal from '../../components/CancleModal';
import DeleteModal from '../../components/DeleteModal';
import Icon from '../../components/Icon';
import Popup from '../../components/Popup';
import TextAreaInput from '../../components/TextAreaInput.tsx';
import TextInput from '../../components/TextInput.tsx';
import { downloadFile } from '../../helpers/downloadFile';
import {
  formatDate,
  formatDateAndTime,
  formatDateTimeString,
} from '../../helpers/formatDate';
import { minutesToHour } from '../../helpers/minutesToHour';
import timeTillMidnight, {
  timeFromMidnight,
} from '../../helpers/timeTillMidnight';
import { onNotify, updateCommon } from '../../store/reducers';
import { RootState } from '../../store/store.ts';
import EditCommentModal from './EditCommentModel';
import Notification from '../../components/Notification';

interface Props {
  closeModal: () => void;
}

const timeTrackingListingHeaders = [
  'User',
  'Start Time',
  'End Time',
  'Total Time',
  'Comment',
  'Action',
];

const bulkUploadListingHeaders = [
  'Client Name',
  'Task Name (period)',
  'Start Time',
  'Stop Time',
  'Total Time',
  'Comment',
];

const trackingHistory = ['Automatically Tracked', 'Manually Tracked'];

type TrackingType = 'automatic' | 'manual';

const curentlyWorkingInitialState = {
  _id: '',
  taskId: '',
  taskName: '',
  period: '',
  clientId: '',
  clientName: '',
  startTime: '',
  stopTime: '',
  totalTime: '',
  isRunning: false,
  userId: '',
  type: '',
  comment: '',
};

type CurrentlyWorking = typeof curentlyWorkingInitialState;

const selectedTaskInitialState = {
  _id: '',
  name: '',
  period: '',
  clientId: '',
  clientName: '',
};

type SelectedTask = typeof selectedTaskInitialState;

type ManualTrackingListItem = {
  startTime: string;
  stopTime: string;
  totalTime: string;
  comment: string;
};

interface State {
  loading: boolean;
  exporting: boolean;
  selectedTask: SelectedTask;
  taskLoading: boolean;
  tasksList: any[];
  trackingType: TrackingType;
  currentTrackedTimeLoading: boolean;
  currentlyWorking: CurrentlyWorking;
  manualTrackingList: ManualTrackingListItem[];
  cancelType: string | undefined;
  showCancelModal: boolean;
  posX: any;
  posY: any;
  showBackDrop: boolean;
  selectedRow: any;
  showEditModal: boolean;
  showDeleteModal: boolean;
  showBulkUpload: boolean;
  selectedBulkUploadPeriod: string;
  activitiesList: any[];
  manuallyTrackedList: any[];
  autoTrackedList: any[];
  totalTimeTracked: number;
  showTimeInHrMin: boolean;
}

const initialState: State = {
  loading: false,
  exporting: false,
  selectedTask: selectedTaskInitialState,
  taskLoading: false,
  tasksList: [],
  trackingType: 'automatic',
  currentTrackedTimeLoading: false,
  currentlyWorking: curentlyWorkingInitialState,
  manualTrackingList: [
    {
      startTime: formatDateAndTime(new Date(), true),
      stopTime: formatDateAndTime(new Date(), true),
      totalTime: '0',
      comment: '',
    },
  ],
  cancelType: undefined,
  showCancelModal: false,
  posX: 0,
  posY: 0,
  showBackDrop: false,
  selectedRow: null,
  showEditModal: false,
  showDeleteModal: false,
  showBulkUpload: false,
  selectedBulkUploadPeriod: formatDate(new Date(), true),
  activitiesList: [],
  manuallyTrackedList: [],
  autoTrackedList: [],
  totalTimeTracked: 0,
  showTimeInHrMin: localStorage.getItem('showTimeInHrMin') === 'true',
};

const TimeTrackingModal = (props: Props) => {
  const commonState = useSelector((state: RootState) => state.common);
  const [state, setState] = useState<State>(initialState);
  const dispatch = useDispatch();

  const getCurrentlyTrackingTime = (workSpaceId: string) => {
    if (!workSpaceId) return;
    agent.TimeTracking.currentlyWorking(workSpaceId)
      .then((res: any) => {
        dispatch(
          updateCommon({
            timeTrackingRunning: res.allRecords[0]?.isRunning
              ? res.allRecords[0].isRunning
              : false,
          }),
        );
      })
      .catch((err: any) => {
        onNotify({
          title: 'Could not fetch records',
          message:
            typeof err?.response?.data?.message === 'object'
              ? 'Something went wrong'
              : err?.response?.data?.message ||
                err?.response?.data?.error ||
                err?.message,
          type: 'danger',
        });
      });
  };

  const handleClose = () => {
    props.closeModal();
    if (!state.currentlyWorking.isRunning) {
      setState((prev) => ({ ...prev, selectedTask: selectedTaskInitialState }));
    }
    saveCommentToLocalStorage();
  };

  const onActionClick = (e: any) => {
    const screenClosness = window.innerHeight - e.clientY;
    const positionY = screenClosness < 145 ? '-4rem' : '1rem';
    setState((prev) => ({ ...prev, posY: positionY, showBackDrop: true }));
  };

  const onDropdownClick = () => {
    setState((prev) => ({ ...prev, showBackDrop: false }));
  };

  const openEditModal = (item: { _id: string }) => {
    setState((prev) => ({ ...prev, selectedRow: item, showBackDrop: false }));
    editModalSetOpen(true);
  };

  const editModalSetOpen = (open: boolean) => {
    setState((prev) => ({ ...prev, showEditModal: open }));
  };

  const openDeleteModal = (item: { _id: string }) => {
    setState((prev) => ({ ...prev, selectedRow: item, showBackDrop: false }));
    deleteModalSetOpen(true);
  };

  const deleteModalSetOpen = (open: boolean) => {
    setState((prev) => ({ ...prev, showDeleteModal: open }));
  };

  const handleBulkUploadPeriodChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prev) => ({ ...prev, selectedBulkUploadPeriod: e.target.value }));
  };

  const handleManualStartTimeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const { manualTrackingList } = state;
    manualTrackingList[index].startTime = e.target.value;
    setState((prev) => ({ ...prev, manualTrackingList }));
  };

  const handleManualStopTimeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const { manualTrackingList } = state;
    manualTrackingList[index].stopTime = e.target.value;
    setState((prev) => ({ ...prev, manualTrackingList }));
  };

  const handleManualTotalTimeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const { manualTrackingList } = state;
    const totalTimeRegex = /^[0-9]*$/;

    if (!totalTimeRegex.test(e.target.value)) {
      return;
    } else {
      setState((prev) => ({
        ...prev,
        manualTrackingList: [
          ...manualTrackingList.slice(0, index),
          ...[
            {
              ...manualTrackingList[index],
              startTime: '',
              stopTime: '',
              totalTime: e.target.value,
            },
          ],
          ...manualTrackingList.slice(index + 1),
        ],
      }));
    }
  };

  const handleManualCommentChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    index: number,
  ) => {
    const { manualTrackingList } = state;
    setState((prev) => ({
      ...prev,
      manualTrackingList: [
        ...manualTrackingList.slice(0, index),
        ...[
          {
            ...manualTrackingList[index],
            comment: e.target.value,
          },
        ],
        ...manualTrackingList.slice(index + 1),
      ],
    }));
  };

  const handleTimeBlur = (
    e: React.FocusEvent<HTMLInputElement>,
    index: number,
  ) => {
    const { manualTrackingList } = state;
    const { startTime, stopTime } = manualTrackingList[index];

    if (startTime && stopTime) {
      const totalTime =
        new Date(stopTime).getTime() - new Date(startTime).getTime();
      manualTrackingList[index].totalTime = (totalTime / 1000 / 60).toString();
      setState((prev) => ({ ...prev, manualTrackingList }));
    }

    const selectedStartTime = new Date(
      manualTrackingList[index].startTime,
    ).getTime();

    const selectedStopTime = new Date(
      manualTrackingList[index].stopTime,
    ).getTime();

    const selectedTime = new Date(e.target.value).getTime();

    if (selectedTime > selectedStopTime) {
      dispatch(
        onNotify({
          title: 'Error',
          message: 'Start time cannot be greater than stop time',
          type: 'danger',
        }),
      );
    }

    if (selectedTime < selectedStartTime) {
      dispatch(
        onNotify({
          title: 'Error',
          message: 'Stop time cannot be less than start time',
          type: 'danger',
        }),
      );
    }
  };

  const handleTotalTimeBlur = (
    e: React.FocusEvent<HTMLInputElement>,
    index: number,
  ) => {
    const { manualTrackingList } = state;
    manualTrackingList[index].totalTime = e.target.value;
    setState((prev) => ({ ...prev, manualTrackingList }));
  };

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setState((prev) => ({
      ...prev,
      currentlyWorking: {
        ...state.currentlyWorking,
        comment: e.target.value,
      },
    }));
  };

  const handleTrackingTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({
      ...prev,
      trackingType: e.target.value as TrackingType,
      currentlyWorking: curentlyWorkingInitialState,
      manualTrackingList: [
        {
          startTime: formatDateAndTime(new Date(), true),
          stopTime: formatDateAndTime(new Date(), true),
          totalTime: '0',
          comment: '',
        },
      ],
    }));
  };

  const handleStartTracking = () => {
    const workSpaceId = commonState?.currentFirm?._id;
    if (!workSpaceId) return;

    setState((prev) => ({
      ...prev,
      loading: true,
      currentlyWorking: curentlyWorkingInitialState,
    }));
    agent.TimeTracking.start(workSpaceId, state.selectedTask?._id)
      .then((res: any) => {
        setState((prev) => ({
          ...prev,
          currentlyWorking: res.data,
          loading: false,
        }));
        getCurrentlyTrackingTime(
          workSpaceId,
          // commonState.updateCommon,
          // commonState.addNotification,
        );
      })
      .catch((err: any) => {
        setState((prev) => ({ ...prev, loading: false }));
        dispatch(
          onNotify({
            title: 'Could not start tracking',
            message:
              typeof err?.response?.data?.message === 'object'
                ? 'Something went wrong'
                : err?.response?.data?.message ||
                  err?.response?.data?.error ||
                  err?.message,
            type: 'danger',
          }),
        );
      });
  };

  const handleStopTracking = () => {
    const { _id, comment } = state.currentlyWorking;
    if (_id && comment) {
      const workSpaceId = commonState?.currentFirm?._id;
      if (!workSpaceId) return;

      setState((prev) => ({ ...prev, loading: true }));
      agent.TimeTracking.stop(workSpaceId, _id, comment)
        .then((res: any) => {
          setState((prev) => ({
            ...prev,
            loading: false,
            currentlyWorking: res.data,
          }));
          dispatch(
            onNotify({
              title: 'Success',
              message: 'Tracking saved successfully',
              type: 'success',
            }),
          );
          getTrackingHistory();
          getCurrentlyTrackingTime(workSpaceId);
          resetAutoTracking();
        })
        .catch((err: any) => {
          setState((prev) => ({ ...prev, loading: false }));
          dispatch(
            onNotify({
              title: 'Error',
              message:
                typeof err?.response?.data?.message === 'object'
                  ? 'Something went wrong'
                  : err?.response?.data?.message ||
                    err?.response?.data?.error ||
                    err?.message,
              type: 'danger',
            }),
          );
        });
    } else {
      dispatch(
        onNotify({
          title: 'Error',
          message: 'Please enter a comment',
          type: 'danger',
        }),
      );
    }
  };

  const saveCommentToLocalStorage = () => {
    state.currentlyWorking.isRunning &&
      state.currentlyWorking.comment &&
      localStorage.setItem('comment', state.currentlyWorking.comment);
  };

  const resetAutoTracking = () => {
    setState((prev) => ({
      ...prev,
      currentlyWorking: curentlyWorkingInitialState,
    }));
  };

  const handleManualListItemAdd = () => {
    const { manualTrackingList } = state;

    manualTrackingList.push({
      startTime: formatDateAndTime(new Date(), true),
      stopTime: formatDateAndTime(new Date(), true),
      totalTime: '0',
      comment: '',
    });

    setState((prev) => ({ ...prev, manualTrackingList }));
  };

  const handleManualTracking = () => {
    const workSpaceId = commonState?.currentFirm?._id;
    if (!workSpaceId) return;

    const { manualTrackingList } = state;
    const { selectedTask } = state;

    setState((prev) => ({ ...prev, loading: true }));

    const trackingData = manualTrackingList
      .filter(
        (item, index) =>
          item.totalTime &&
          item.comment &&
          item.startTime &&
          item.stopTime &&
          index !== manualTrackingList.length - 1,
      )
      .map((item) => ({
        startTime: item.startTime ? new Date(item.startTime).toISOString() : '',
        stopTime: item.stopTime ? new Date(item.stopTime).toISOString() : '',
        totalTime: parseInt(item.totalTime.split(' ')[0]),
        comment: item.comment,
      }));

    const recordWithoutTime = trackingData.filter(
      (item) =>
        !item.totalTime || !item.comment || !item.startTime || !item.stopTime,
    );

    const recordWithInvalidTime = trackingData.filter(
      (item) => item.totalTime <= 0,
    );

    if (recordWithInvalidTime.length) {
      setState((prev) => ({ ...prev, loading: false }));
      dispatch(
        onNotify({
          title: 'Error',
          message: 'Total Time cannot be less than or equal to 0',
          type: 'danger',
        }),
      );
      return;
    }

    if (recordWithoutTime.length || !trackingData.length) {
      setState((prev) => ({ ...prev, loading: false }));
      dispatch(
        onNotify({
          title: 'Error',
          message: 'Time and comment are mandatory for all records',
          type: 'danger',
        }),
      );
      return;
    }

    agent.TimeTracking.manualTracking(
      workSpaceId,
      selectedTask?._id,
      trackingData,
    )
      .then((res: any) => {
        setState((prev) => ({ ...prev, loading: false }));
        resetManualTracking();
        getTrackingHistory();
        dispatch(
          onNotify({
            title: 'Time tracking successful',
            message: res?.data?.message || 'Time tracking successful',
            type: 'success',
          }),
        );
      })
      .catch((err: any) => {
        setState((prev) => ({ ...prev, loading: false }));
        dispatch(
          onNotify({
            title: 'Error',
            message:
              typeof err?.response?.data?.message === 'object'
                ? 'Something went wrong'
                : err?.response?.data?.message ||
                  err?.response?.data?.error ||
                  err?.message,
            type: 'danger',
          }),
        );
      });
  };

  const resetManualTracking = () => {
    setState((prev) => ({
      ...prev,
      manualTrackingList: [
        {
          startTime: formatDateAndTime(new Date(), true),
          stopTime: formatDateAndTime(new Date(), true),
          totalTime: '0',
          comment: '',
        },
      ],
    }));
  };

  const handleBulkTracking = () => {};

  const cancelBulkTracking = () => {};

  const showCancelModal = (type?: string) =>
    setState((prev) => ({ ...prev, showCancelModal: true, cancelType: type }));

  const hideCancelModal = () =>
    setState((prev) => ({ ...prev, showCancelModal: false }));

  const handleCancel = () => {
    if (state.cancelType === 'bulkTracking') {
      cancelBulkTracking();
    } else {
      resetManualTracking();
    }
    hideCancelModal();
  };

  const getCurrentlyWorking = () => {
    const workSpaceId = commonState?.currentFirm?._id;
    if (!workSpaceId) return;

    setState((prev) => ({ ...prev, currentTrackedTimeLoading: true }));
    agent.TimeTracking.currentlyWorking(workSpaceId)
      .then((res: { allRecords: CurrentlyWorking[] }) => {
        setState((prev) => ({
          ...prev,
          currentTrackedTimeLoading: false,
          currentlyWorking: res.allRecords.length
            ? {
                ...res.allRecords[0],
                comment: localStorage.getItem('comment') || '',
              }
            : {
                ...curentlyWorkingInitialState,
                comment: localStorage.getItem('comment') || '',
              },
        }));
        res.allRecords.length > 0 &&
          res.allRecords[0].isRunning &&
          setState((prev) => ({
            ...prev,
            selectedTask: {
              _id: res.allRecords.length ? res.allRecords[0].taskId : '',
              name: res.allRecords.length ? res.allRecords[0].taskName : '',
              clientId: res.allRecords.length ? res.allRecords[0].clientId : '',
              clientName: res.allRecords.length
                ? res.allRecords[0].clientName
                : '',
              period: res.allRecords.length ? res.allRecords[0].period : '',
            },
          }));
        getCurrentlyTrackingTime(
          workSpaceId,
          // commonState.updateCommon,
          // commonState.addNotification,
        );
      })
      .catch((err: any) => {
        setState((prev) => ({ ...prev, currentTrackedTimeLoading: false }));
        dispatch(
          onNotify({
            title: 'Error',
            message:
              typeof err?.response?.data?.message === 'object'
                ? 'Something went wrong : Could not fetch records'
                : err?.response?.data?.message ||
                  err?.response?.data?.error ||
                  err?.message,
            type: 'danger',
          }),
        );
      });
  };

  const getTrackingHistory = (download?: boolean) => {
    const workSpaceId = commonState?.currentFirm?._id;
    if (!workSpaceId) return;

    download
      ? setState((prev) => ({ ...prev, exporting: true }))
      : setState((prev) => ({ ...prev, loading: true }));

    //issue with date causing the api to not return time tracking list
    const selectedDate = new Date(state.selectedBulkUploadPeriod);
    const dateFrom = state.showBulkUpload
      ? timeFromMidnight(selectedDate).toISOString()
      : timeFromMidnight(new Date(2018, 0, 1)).toISOString();
    const dateTo = timeTillMidnight(selectedDate).toISOString();

    agent.TimeTracking.getTrackingHistory(
      workSpaceId,
      dateFrom,
      dateTo,
      0,
      // this added as sometimes api is getting hit without selectedTask and in that case getting data for whole workpsace and server getting closed
      state.selectedTask?._id ? 100000 : 100,
      download ? true : false,
      [],
      [],
      '',
      state.selectedTask?._id ? [state.selectedTask?._id] : [],
    )
      .then((res: any) => {
        if (download) {
          setState((prev) => ({ ...prev, exporting: false }));
          return downloadFile(
            res,
            `Finexo PMS Time Tracking Report-${formatDateTimeString(
              new Date(),
            )}.xlsx`,
          );
        } else {
          const totalTimeTracked = res.finalList.reduce(
            (counter: number, item: any) => counter + item.totalTime,
            0,
          );
          const manuallyTrackedList = res.finalList
            .filter((item: any) => item.type === 'manual')
            .sort(
              (a: any, b: any) =>
                new Date(b.startTime).getTime() -
                new Date(a.startTime).getTime(),
            );

          const autoTrackedList = res.finalList
            .filter((item: any) => item.type === 'automatic')
            .sort(
              (a: any, b: any) =>
                new Date(b.startTime).getTime() -
                new Date(a.startTime).getTime(),
            );

          setState((prev) => ({
            ...prev,
            loading: false,
            manuallyTrackedList,
            autoTrackedList,
            totalTimeTracked,
          }));
        }
      })
      .catch((err: any) => {
        download
          ? setState((prev) => ({ ...prev, exporting: false }))
          : setState((prev) => ({ ...prev, loading: false }));
        dispatch(
          onNotify({
            title: 'Error',
            message:
              typeof err?.response?.data?.message === 'object'
                ? 'Something went wrong : Could not fetch records'
                : err?.response?.data?.message ||
                  err?.response?.data?.error ||
                  err?.message,
            type: 'danger',
          }),
        );
      });
  };

  const handleTimeFormatChange = () => {
    setState((prev) => ({ ...prev, showTimeInHrMin: !state.showTimeInHrMin }));

    localStorage.setItem(
      'showTimeInHrMin',
      state.showTimeInHrMin?.toString() || 'false',
    );
  };

  const [initialLoaded, setInitialLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setInitialLoaded(true);
    }, 1000);
  }, []);

  useEffect(() => {
    if (initialLoaded === false) return;
    if (commonState?.currentModal?.data?.selectedTask) {
      setState((prev) => ({
        ...prev,
        selectedTask: commonState?.currentModal?.data?.selectedTask,
      }));
    }
    getCurrentlyWorking();
  }, [commonState?.currentModal?.data]);

  useEffect(() => {
    if (initialLoaded === false) return;
    getTrackingHistory();
  }, [state.selectedTask?._id]);

  useEffect(() => {
    if (initialLoaded === false) return;
    getTrackingHistory();
  }, [state.selectedBulkUploadPeriod]);

  useEffect(() => {
    if (initialLoaded === false) return;
    const lastItem =
      state.manualTrackingList[state.manualTrackingList.length - 1];
    if (lastItem.comment !== '') {
      handleManualListItemAdd();
    }
  }, [state.manualTrackingList]);

  const onLoad = () => {
    getTrackingHistory();
    resetAutoTracking();
  };

  const onDeleteTimeTracking = () => {
    onLoad();
    const workSpaceId = commonState?.currentFirm?._id;
    getCurrentlyTrackingTime(
      workSpaceId,
      // commonState.updateCommon,
      // commonState.addNotification,
    );
  };

  return (
    <Transition.Root
      show={commonState?.currentModal?.modalName === 'TIME_TRACKING_MODAL'}
      as={Fragment}
      appear
    >
      <Dialog
        as='div'
        className='fixed z-40 inset-0 overflow-y-auto'
        onClose={() => null}
      >
        <div className='flex items-center justify-center h-full py-8 px-2'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'>
              <Notification />
            </Dialog.Overlay>
          </Transition.Child>
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div
              className={`vertical-scroll inline-block bg-white rounded-lg ${
                state.selectedTask?._id ||
                state.showBulkUpload ||
                state.currentTrackedTimeLoading
                  ? 'w-11/12 lg:w-10/12 h-full'
                  : 'w-11/12 md:w-2/3 lg:w-1/2 h-fit min-h-[50vh]'
              } px-6 md:px-8 py-8 overflow-auto shadow-xl transform transition-all`}
            >
              {state.showCancelModal && (
                <CancleModal
                  title='Clear All entered data'
                  message='Are you sure you want to Clear the entered data? This action cannot be undone.'
                  loading={state.loading}
                  showModal={state.showCancelModal}
                  hideModal={hideCancelModal}
                  cancelText='Clear All'
                  onCancel={handleCancel}
                />
              )}
              {state.showEditModal && (
                <EditCommentModal
                  state={state}
                  onLoad={onLoad}
                  editModalSetOpen={editModalSetOpen}
                />
              )}
              {state.showDeleteModal && (
                <DeleteModal
                  type='time tracking'
                  state={state}
                  onLoad={onDeleteTimeTracking}
                  deleteModalSetOpen={deleteModalSetOpen}
                />
              )}
              <div className='flex items-center justify-between'>
                <h1 className='text-xl font-medium leading-6 text-gray-900'>
                  Time Tracking
                </h1>
                <button type='button' onClick={handleClose}>
                  <span className='sr-only'>Close</span>
                  <XMarkIcon
                    className='h-6 w-6 text-gray-500'
                    aria-hidden='true'
                  />
                </button>
              </div>
              {/* For showing loading skeleton */}
              {state.currentTrackedTimeLoading ? (
                <div className='text-base text-gray-800 sm:overflow-auto min-h-[60vh] min-w-[70vw] space-y-6 mt-6'>
                  <dl className='divide-y-2 divide-gray-200'>
                    <div className='py-5 sm:grid sm:grid-cols-3 sm:gap-4'>
                      <dt className='text-base font-medium text-gray-500'>
                        <Skeleton className='h-5 w-32' />
                      </dt>
                      <dd className='mt-1 text-gray-900 sm:col-span-2 sm:mt-0'>
                        <span className='space-x-2 font-semibold'>
                          <span className='inline-block'>
                            <Skeleton className='h-6 w-52' />
                          </span>
                          <span>/</span>
                          <span className='inline-block'>
                            <Skeleton className='h-6 w-40' />
                          </span>
                          <span>/</span>
                          <span className='inline-block'>
                            <Skeleton className='h-6 w-40' />
                          </span>
                        </span>
                      </dd>
                    </div>
                    <div className='py-5 sm:grid sm:grid-cols-3 sm:gap-4'>
                      <dt className='text-base font-medium text-gray-500'>
                        <Skeleton className='h-5 w-32' />
                      </dt>
                      <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 flex items-center gap-8'>
                        <Skeleton
                          className={`h-5 ${
                            commonState.timeTrackingRunning ? 'w-40' : 'w-56'
                          }`}
                        />
                        {!commonState.timeTrackingRunning && (
                          <Skeleton className='h-5 w-56' />
                        )}
                      </dd>
                    </div>
                    {commonState.timeTrackingRunning ? (
                      <>
                        <div className='py-5 sm:grid sm:grid-cols-3 sm:gap-4'>
                          <dt className='text-base font-medium text-gray-500'>
                            <Skeleton className='h-5 w-32' />
                          </dt>
                          <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
                            <Skeleton className='h-24 w-full' />
                          </dd>
                        </div>
                        <div className='py-5 sm:grid sm:grid-cols-3 sm:gap-4'>
                          <dt></dt>
                          <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 flex items-center gap-8'>
                            <Skeleton className='h-7 w-40' />
                            <Skeleton className='h-7 w-40' />
                          </dd>
                        </div>
                      </>
                    ) : (
                      <div className='py-5 sm:grid sm:grid-cols-3 sm:gap-4'>
                        <dt className='text-base font-medium text-gray-500'>
                          <Skeleton className='h-5 w-32' />
                        </dt>
                        <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
                          <Skeleton className='h-8 w-28' />
                        </dd>
                      </div>
                    )}
                    {[...Array(5)].map((_, index) => (
                      <div
                        key={index}
                        className='w-full min-w-fit grid grid-cols-6 gap-4 py-3'
                      >
                        {[...Array(6)].map((_, index) => (
                          <div key={index}>
                            <Skeleton className='h-4' />
                          </div>
                        ))}
                      </div>
                    ))}
                  </dl>
                </div>
              ) : state.selectedTask?._id ? (
                /* For Showing Time Tracking Modal start and stop buttons and task's tracking history */
                <div className='text-base text-gray-800 space-y-6 mt-6'>
                  <dl className='divide-y-2 divide-gray-200'>
                    <div className='py-5 sm:grid sm:grid-cols-3 sm:gap-4'>
                      <dt className='text-base font-medium text-gray-500'>
                        Task
                      </dt>
                      <dd className='mt-1 text-gray-900 sm:col-span-2 sm:mt-0'>
                        <span className='space-x-2 font-semibold'>
                          <span>
                            {state.selectedTask?.clientName
                              ? `${state.selectedTask?.clientName}`
                              : ''}
                          </span>
                          <span>
                            {state.selectedTask?.name
                              ? `/ ${state.selectedTask?.name}`
                              : ''}
                          </span>
                          <span>
                            {state.selectedTask?.period
                              ? `/ ${state.selectedTask?.period}`
                              : ''}
                          </span>
                        </span>
                      </dd>
                    </div>

                    {state.trackingType === 'automatic' &&
                    state.currentlyWorking?.startTime ? (
                      <div className='py-5 sm:grid sm:grid-cols-3 sm:gap-4'>
                        <dt className='text-base font-medium text-gray-500'>
                          {state.loading ? (
                            <Skeleton className='h-4 w-32' />
                          ) : (
                            'Start Time'
                          )}
                        </dt>
                        <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
                          {state.loading ? (
                            <Skeleton className='h-4 w-64' />
                          ) : (
                            formatDateTimeString(
                              state.currentlyWorking?.startTime,
                            )
                          )}
                        </dd>
                      </div>
                    ) : (
                      <div className='py-5 sm:grid sm:grid-cols-3 sm:gap-4'>
                        <dt className='text-base font-medium text-gray-500'>
                          {state.loading ? (
                            <Skeleton className='h-4 w-32' />
                          ) : (
                            'Type'
                          )}
                        </dt>
                        <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 flex items-center gap-4 md:gap-16'>
                          {state.loading ? (
                            <Skeleton className='h-4 w-64' />
                          ) : (
                            <>
                              <div className='flex items-center gap-3'>
                                <input
                                  id='automatic'
                                  name='trackingType'
                                  type='radio'
                                  value='automatic'
                                  checked={state.trackingType === 'automatic'}
                                  className='focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer'
                                  onChange={handleTrackingTypeChange}
                                />
                                <label
                                  htmlFor='automatic'
                                  className='text-sm font-medium text-gray-700 cursor-pointer'
                                >
                                  Automatic Time Tracking
                                </label>
                              </div>
                              <div className='flex items-center gap-3'>
                                <input
                                  id='manual'
                                  name='trackingType'
                                  type='radio'
                                  value='manual'
                                  checked={state.trackingType === 'manual'}
                                  className='focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer'
                                  onChange={handleTrackingTypeChange}
                                />
                                <label
                                  htmlFor='manual'
                                  className='text-sm font-medium text-gray-700 cursor-pointer'
                                >
                                  Manual Time Tracking
                                </label>
                              </div>
                            </>
                          )}
                        </dd>
                      </div>
                    )}

                    {state.trackingType === 'automatic' ? (
                      state.currentlyWorking?.stopTime ? (
                        <>
                          <div className='py-5 sm:grid sm:grid-cols-3 sm:gap-4'>
                            <dt className='text-base font-medium text-gray-500'>
                              {state.loading ? (
                                <Skeleton className='h-4 w-32' />
                              ) : (
                                'Stop Time'
                              )}
                            </dt>
                            <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
                              {state.loading ? (
                                <Skeleton className='h-4 w-64' />
                              ) : (
                                formatDateTimeString(
                                  state.currentlyWorking?.stopTime,
                                )
                              )}
                            </dd>
                          </div>
                        </>
                      ) : (
                        <>
                          {state.currentlyWorking.isRunning && (
                            <div className='py-5 sm:grid sm:grid-cols-3 sm:gap-4'>
                              <dt className='text-base font-medium text-gray-500'>
                                Comment <span className='text-red-600'>*</span>
                              </dt>
                              <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
                                <TextAreaInput
                                  name='comment'
                                  placeholder='Comment'
                                  value={state.currentlyWorking?.comment}
                                  onChange={handleCommentChange}
                                />
                              </dd>
                            </div>
                          )}
                          <div className='py-5 grid grid-cols-3 gap-4 items-center'>
                            <dt className='text-base font-medium text-gray-500'>
                              Track Time
                            </dt>
                            <dd className='text-sm text-gray-900 col-span-2 flex items-center gap-6'>
                              {state.currentlyWorking.isRunning ? (
                                <>
                                  <Button
                                    name='Stop'
                                    icon={StopIcon}
                                    onClick={handleStopTracking}
                                    disabled={state.loading}
                                  />
                                  <Button
                                    name='Cancel'
                                    onClick={() =>
                                      openDeleteModal(state.currentlyWorking)
                                    }
                                    className='w-fit inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2 px-4 text-base bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:text-sm'
                                  />
                                </>
                              ) : (
                                <Button
                                  name='Start'
                                  icon={PlayIcon}
                                  onClick={handleStartTracking}
                                />
                              )}
                            </dd>
                          </div>
                        </>
                      )
                    ) : (
                      state.trackingType === 'manual' && (
                        <>
                          {state.manualTrackingList.map((item, index) => (
                            <div
                              key={index}
                              className='grid grid-cols-2 md:grid-cols-4 lg:grid-cols-9 gap-3 py-4 text-sm text-gray-500'
                            >
                              <div className='lg:col-span-2'>
                                <label
                                  htmlFor='start-time'
                                  className='block text-sm font-medium text-gray-700 mb-2'
                                >
                                  Start Time{' '}
                                  <span className='text-red-500'>*</span>
                                </label>
                                <TextInput
                                  name='start-time'
                                  id='start-time'
                                  type='datetime-local'
                                  required={true}
                                  max={item.stopTime}
                                  value={item.startTime}
                                  step='60'
                                  onChange={(e) => {
                                    handleManualStartTimeChange(e, index);
                                    handleTimeBlur(e, index);
                                  }}
                                  // onBlur={(e) => handleTimeBlur(e, index)}
                                />
                              </div>
                              <div className='lg:col-span-2'>
                                <label
                                  htmlFor='stop-time'
                                  className='block text-sm font-medium text-gray-700 mb-2'
                                >
                                  Stop Time{' '}
                                  <span className='text-red-500'>*</span>
                                </label>
                                <TextInput
                                  name='stop-time'
                                  id='stop-time'
                                  type='datetime-local'
                                  required={true}
                                  min={item.startTime}
                                  value={item.stopTime}
                                  step='60'
                                  onChange={(e) => {
                                    handleManualStopTimeChange(e, index);
                                    handleTimeBlur(e, index);
                                  }}
                                  // onBlur={(e) => handleTimeBlur(e, index)}
                                />
                              </div>
                              <div className='lg:col-span-1'>
                                <label
                                  htmlFor='total-time'
                                  className='block text-sm font-medium text-gray-700 mb-2'
                                >
                                  Time (in Mins){' '}
                                  <span className='text-red-500'>*</span>
                                </label>
                                <TextInput
                                  name='total-time'
                                  id='total-time'
                                  type='text'
                                  pattern='[0-9]*'
                                  required
                                  placeholder='Total Time'
                                  value={item.totalTime}
                                  onChange={(e) =>
                                    handleManualTotalTimeChange(e, index)
                                  }
                                  onBlur={(e) => handleTotalTimeBlur(e, index)}
                                />
                              </div>
                              <div className='lg:col-span-4'>
                                <label
                                  htmlFor='comment'
                                  className='block text-sm font-medium text-gray-700 mb-2'
                                >
                                  Comment{' '}
                                  <span className='text-red-600'>*</span>
                                </label>
                                <TextAreaInput
                                  name='comment'
                                  id='comment'
                                  rows={1}
                                  placeholder='Comment'
                                  value={item.comment}
                                  onChange={(e) =>
                                    handleManualCommentChange(e, index)
                                  }
                                />
                              </div>
                            </div>
                          ))}
                          <div className='py-5 sm:grid sm:grid-cols-3 sm:gap-4'>
                            <dt></dt>
                            <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 flex items-center gap-8 justify-end'>
                              <Button
                                name='Save'
                                onClick={handleManualTracking}
                                disabled={state.loading}
                              />
                              <Button
                                name='ClearAll'
                                onClick={showCancelModal}
                                disabled={
                                  state.loading ||
                                  (state.manualTrackingList.length === 1 &&
                                    state.manualTrackingList[
                                      state.manualTrackingList.length - 1
                                    ].startTime === '' &&
                                    state.manualTrackingList[
                                      state.manualTrackingList.length - 1
                                    ].stopTime === '' &&
                                    state.manualTrackingList[
                                      state.manualTrackingList.length - 1
                                    ].totalTime === '' &&
                                    state.manualTrackingList[
                                      state.manualTrackingList.length - 1
                                    ].comment === '')
                                }
                                className='w-fit inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2 px-4 text-base bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:w-32 sm:text-sm'
                              />
                            </dd>
                          </div>
                        </>
                      )
                    )}
                  </dl>
                </div>
              ) : state.showBulkUpload ? (
                /* For showing bulk upload listing */
                <div className='text-base text-gray-800 space-y-6 mt-6'>
                  <div className='flex items-center justify-between'>
                    <h2 className='text-base leading-6 font-medium text-gray-900'>
                      Bulk Upload Time Sheet
                    </h2>
                    <div className='w-40'>
                      <input
                        type='date'
                        className='w-full text-sm border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500'
                        value={state.selectedBulkUploadPeriod}
                        onChange={handleBulkUploadPeriodChange}
                      />
                    </div>
                  </div>
                  <div
                    id='table-scroll'
                    className='overflow-auto m-1 bg-white my-2 shadow rounded-md'
                  >
                    <div className='inline-block min-w-full align-middle'>
                      <div className='shadow ring-1 ring-black ring-opacity-5 rounded-lg'>
                        <table className='min-w-full divide-y divide-gray-300'>
                          <thead className='bg-gray-100'>
                            <tr>
                              <th
                                colSpan={bulkUploadListingHeaders.length}
                                className='py-3 px-4 text-left text-xs font-medium text-gray-800 uppercase tracking-wider'
                              >
                                Your Activities
                              </th>
                            </tr>
                          </thead>
                          <tbody className='bg-white'>
                            <tr className='bg-gray-50 border-b'>
                              {bulkUploadListingHeaders.map(
                                (header: string) => (
                                  <td
                                    key={header}
                                    className='whitespace-nowrap py-3 px-4 text-sm text-gray-800 font-semibold'
                                  >
                                    {header}
                                  </td>
                                ),
                              )}
                            </tr>
                            {!state.loading ? (
                              state.activitiesList?.length > 0 ? (
                                state.activitiesList.map(
                                  (item: any, index: number) => (
                                    <tr
                                      key={item?._id}
                                      className={
                                        index % 2 === 0
                                          ? 'bg-white'
                                          : 'bg-gray-50'
                                      }
                                    >
                                      <td className='max-w-[20ch] whitespace-nowrap py-4 px-4 text-sm text-gray-800'>
                                        <Popup
                                          content={item?.clientName || '-'}
                                          className='w-full max-w-fit'
                                        >
                                          <p className='hover:underline font-bold truncate'>
                                            {item?.clientName || '-'}
                                          </p>
                                        </Popup>
                                      </td>
                                      <td className='max-w-[20ch] whitespace-nowrap py-4 px-4 text-sm text-gray-800'>
                                        <Popup
                                          content={`${item?.taskName || '-'} ${
                                            item?.period
                                              ? `(${item?.period})`
                                              : ''
                                          }`}
                                          className='w-full max-w-fit'
                                        >
                                          <p className='hover:underline font-bold truncate'>
                                            {`${item?.taskName || '-'} ${
                                              item?.period
                                                ? `(${item?.period})`
                                                : ''
                                            }`}
                                          </p>
                                        </Popup>
                                      </td>
                                      <td className='whitespace-nowrap py-4 px-4 text-sm text-gray-800'>
                                        <input
                                          id='start-time'
                                          type='datetime-local'
                                          className='w-40 text-sm border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500'
                                          max={state.currentlyWorking?.stopTime}
                                          value={
                                            state.currentlyWorking?.startTime
                                          }
                                        />
                                      </td>
                                      <td className='whitespace-nowrap py-4 px-4 text-sm text-gray-800'>
                                        <input
                                          id='stop-time'
                                          type='datetime-local'
                                          className='w-40 text-sm border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500'
                                          min={
                                            state.currentlyWorking?.startTime
                                          }
                                          value={
                                            state.currentlyWorking?.stopTime
                                          }
                                        />
                                      </td>
                                      <td className='whitespace-nowrap py-4 px-4 text-sm text-gray-800'>
                                        <input
                                          id='total-time'
                                          type='text'
                                          pattern='[0-9]*'
                                          required
                                          className='w-40 text-sm border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500'
                                          placeholder='Total Time'
                                          value={
                                            state.currentlyWorking.totalTime
                                          }
                                        />
                                      </td>
                                      <td className='whitespace-nowrap py-4 px-4 text-sm text-gray-800'>
                                        <TextAreaInput
                                          name='comment'
                                          id='comment'
                                          rows={2}
                                          placeholder='Comment'
                                          value={state.currentlyWorking.comment}
                                          onChange={handleCommentChange}
                                        />
                                      </td>
                                    </tr>
                                  ),
                                )
                              ) : (
                                <tr>
                                  <td
                                    colSpan={bulkUploadListingHeaders.length}
                                    className='px-4 py-4 whitespace-nowrap text-sm text-center text-gray-500'
                                  >
                                    No records found
                                  </td>
                                </tr>
                              )
                            ) : (
                              [...Array(5)].map((e, i) => (
                                <tr key={`tr-${i}`} className='bg-white'>
                                  {[
                                    ...Array(bulkUploadListingHeaders.length),
                                  ].map((e, i) => (
                                    <td
                                      key={`td-${i}`}
                                      className='whitespace-nowrap py-4 px-4 text-sm text-gray-500'
                                    >
                                      <Skeleton />
                                    </td>
                                  ))}
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                /* For showing no timer running message */
                !state.currentlyWorking.isRunning &&
                !state.selectedTask?._id && (
                  <div className='text-base text-gray-800 sm:overflow-auto space-y-6 mt-6 mx-6 md:mx-14 lg:mx-20'>
                    <div className='text-center my-16 rounded-lg'>
                      <h3 className='mt-2 text-sm font-medium text-gray-900'>
                        Timer is not running.
                      </h3>
                      <p className='mt-2 text-sm text-gray-500'>
                        You can start a timer from task details page for the
                        task or Insert time entries manually.
                      </p>
                      <div className='mt-6 w-full flex items-center justify-center'>
                        <Button
                          type='link'
                          to={`/${commonState.currentFirm?._id}/tasks/list`}
                          name='Go to Task List'
                          onClick={props.closeModal}
                        />
                      </div>
                    </div>
                  </div>
                )
              )}
              {(state.showBulkUpload || state.selectedTask?._id) && (
                <>
                  {/* Time tracking history of the current task */}
                  {state.selectedTask?._id && (
                    <>
                      <h2 className='text-lg font-medium leading-6 text-gray-900 mt-6'>
                        Time Tracking Activity for the task
                      </h2>
                      <div className='flex items-center justify-between'>
                        <div className='relative flex items-start mt-6'>
                          <div className='flex h-5 items-center'>
                            <input
                              id='time-duration-format'
                              name='time-duration-format'
                              type='checkbox'
                              className='h-4 w-4 rounded border-gray-400 cursor-pointer text-indigo-600 focus:ring-indigo-500'
                              checked={state.showTimeInHrMin}
                              onChange={handleTimeFormatChange}
                            />
                          </div>
                          <div className='ml-3 text-sm'>
                            <label
                              htmlFor='time-duration-format'
                              className='font-medium cursor-pointer text-gray-700'
                            >
                              Show Time in Hr:Min
                            </label>
                          </div>
                        </div>
                        <Button
                          name='Export'
                          icon={
                            state.exporting ? 'loading' : 'outline/download'
                          }
                          onClick={() => getTrackingHistory(true)}
                        />
                      </div>
                    </>
                  )}
                  {trackingHistory.map((header: string) => {
                    const list = header.includes('Manually')
                      ? state.manuallyTrackedList
                      : state.autoTrackedList;

                    const totalTimeTracked = list.reduce(
                      (counter: number, item: any) => counter + item.totalTime,
                      0,
                    );

                    const headers = state.showBulkUpload
                      ? bulkUploadListingHeaders
                      : timeTrackingListingHeaders;

                    return (
                      <div
                        id='table-scroll'
                        className='overflow-auto mt-8 bg-white my-2 shadow rounded-md'
                        key={header}
                      >
                        <div className='inline-block min-w-full align-middle'>
                          <div className='shadow ring-1 ring-black ring-opacity-5 rounded-lg'>
                            <div
                              key={header}
                              className='py-3 px-4 text-left text-xs font-bold bg-gray-100 text-gray-800 uppercase tracking-wider'
                            >
                              {header}
                            </div>
                            <table
                              key={`bulkUploadListing-${header}`}
                              className='min-w-full divide-y divide-gray-300'
                            >
                              <thead className='bg-gray-50'>
                                <tr>
                                  {headers.map((header: string) => (
                                    <th
                                      key={header}
                                      className={`whitespace-nowrap py-3 px-4 text-sm text-gray-800 font-semibold ${
                                        header === 'Action'
                                          ? 'text-center'
                                          : 'text-left'
                                      }`}
                                    >
                                      {header}
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody className='bg-white'>
                                {!state.loading ? (
                                  list?.length > 0 ? (
                                    <>
                                      {list.map((item: any) => {
                                        const userName = commonState.users.find(
                                          (user: any) =>
                                            user?._id === item.userId,
                                        )?.name;

                                        return (
                                          <tr
                                            key={item?._id}
                                            className='even:bg-white odd:bg-gray-50'
                                          >
                                            {state.showBulkUpload ? (
                                              <>
                                                <td className='max-w-[20ch] whitespace-nowrap py-4 px-4 text-sm text-gray-800'>
                                                  <Popup
                                                    content={
                                                      item?.clientName || '-'
                                                    }
                                                    className='w-full max-w-fit'
                                                  >
                                                    <p className='hover:underline font-bold truncate'>
                                                      {item?.clientName || '-'}
                                                    </p>
                                                  </Popup>
                                                </td>
                                                <td className='max-w-[20ch] whitespace-nowrap py-4 px-4 text-sm text-gray-800'>
                                                  <Popup
                                                    content={`${
                                                      item?.taskName || '-'
                                                    } ${
                                                      item?.period
                                                        ? `(${item?.period})`
                                                        : ''
                                                    }`}
                                                    className='w-full max-w-fit'
                                                  >
                                                    <p className='hover:underline font-bold truncate'>
                                                      {`${
                                                        item?.taskName || '-'
                                                      } ${
                                                        item?.period
                                                          ? `(${item?.period})`
                                                          : ''
                                                      }`}
                                                    </p>
                                                  </Popup>
                                                </td>
                                              </>
                                            ) : (
                                              <td className='max-w-[20ch] whitespace-nowrap py-4 px-4 text-sm text-gray-800'>
                                                <Popup
                                                  content={userName || '-'}
                                                  className='w-full max-w-fit'
                                                >
                                                  <p className='hover:underline font-bold truncate'>
                                                    {userName || '-'}
                                                  </p>
                                                </Popup>
                                              </td>
                                            )}
                                            <td className='whitespace-nowrap py-4 px-4 text-sm text-gray-800'>
                                              {item.startTime
                                                ? formatDateTimeString(
                                                    item.startTime,
                                                  )
                                                : '-'}
                                            </td>
                                            <td className='whitespace-nowrap py-4 px-4 text-sm text-gray-800'>
                                              {item.stopTime
                                                ? formatDateTimeString(
                                                    item.stopTime,
                                                  )
                                                : '-'}
                                            </td>
                                            <td className='whitespace-nowrap py-4 px-4 text-sm text-gray-800'>
                                              {state.showTimeInHrMin &&
                                              item.totalTime
                                                ? `${minutesToHour(
                                                    item.totalTime,
                                                  )}` || '-'
                                                : item.totalTime + ' mins' ||
                                                  '-'}
                                            </td>
                                            <td className='max-w-[20ch] whitespace-nowrap py-4 px-4 text-sm text-gray-800'>
                                              <Popup
                                                content={item.comment || '-'}
                                                className='w-full max-w-fit'
                                              >
                                                <p className='hover:underline font-bold truncate'>
                                                  {item.comment || '-'}
                                                </p>
                                              </Popup>
                                            </td>
                                            {state.selectedTask?._id && (
                                              <td className='px-3 py-4 whitespace-nowrap text-sm text-gray-900 font-bold capitalize text-center'>
                                                <Menu
                                                  as='div'
                                                  className='inline-block relative'
                                                >
                                                  <Menu.Button
                                                    onClick={onActionClick}
                                                  >
                                                    <span className='sr-only'>
                                                      Open options
                                                    </span>
                                                    <Icon
                                                      name='horizontal-dots'
                                                      className='h-5 w-5'
                                                      aria-hidden='true'
                                                    />
                                                  </Menu.Button>
                                                  {state.showBackDrop && (
                                                    <div
                                                      className='fixed top-0 left-0 z-10 w-full h-screen'
                                                      onClick={onDropdownClick}
                                                    ></div>
                                                  )}
                                                  <Transition
                                                    as={Fragment}
                                                    enter='transition ease-out duration-100'
                                                    enterFrom='transform opacity-0 scale-95'
                                                    enterTo='transform opacity-100 scale-100'
                                                    leave='transition ease-in duration-75'
                                                    leaveFrom='transform opacity-100 scale-100'
                                                    leaveTo='transform opacity-0 scale-95'
                                                  >
                                                    <div
                                                      style={{
                                                        position: 'fixed',
                                                        zIndex: 200,
                                                        top: `${state.posY}px`,
                                                        right: `${
                                                          state.posX + 70
                                                        }px`,
                                                        margin: '0.5rem',
                                                      }}
                                                    >
                                                      <Menu.Items className='overscroll-none mt-1 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
                                                        <div className='py-1'>
                                                          <Menu.Item>
                                                            <button
                                                              className='flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900'
                                                              onClick={() =>
                                                                openEditModal(
                                                                  item,
                                                                )
                                                              }
                                                            >
                                                              <Icon
                                                                name='edit'
                                                                className='h-5 w-5 mr-2'
                                                              />
                                                              <span>
                                                                Edit comment
                                                              </span>
                                                            </button>
                                                          </Menu.Item>
                                                          <Menu.Item>
                                                            <button
                                                              className='flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900'
                                                              onClick={() =>
                                                                openDeleteModal(
                                                                  item,
                                                                )
                                                              }
                                                            >
                                                              <Icon
                                                                name='delete'
                                                                className='h-5 w-5 mr-2'
                                                              />
                                                              <span>
                                                                Delete
                                                              </span>
                                                            </button>
                                                          </Menu.Item>
                                                        </div>
                                                      </Menu.Items>
                                                    </div>
                                                  </Transition>
                                                </Menu>
                                              </td>
                                            )}
                                          </tr>
                                        );
                                      })}
                                      <tr className='even:bg-white odd:bg-gray-50'>
                                        <td className='whitespace-nowrap py-4 px-4 text-sm text-gray-800'>
                                          Total Time Tracked
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td className='whitespace-nowrap py-4 px-4 text-sm text-gray-800'>
                                          {state.showTimeInHrMin
                                            ? minutesToHour(totalTimeTracked)
                                            : totalTimeTracked + ' mins'}
                                        </td>
                                        <td></td>
                                        <td></td>
                                      </tr>
                                    </>
                                  ) : (
                                    <tr>
                                      <td
                                        colSpan={headers.length}
                                        className='px-4 py-4 whitespace-nowrap text-center text-sm text-gray-500'
                                      >
                                        No records found
                                      </td>
                                    </tr>
                                  )
                                ) : (
                                  [...Array(5)].map((e, i) => (
                                    <tr key={`tr-${i}`} className='bg-white'>
                                      {[...Array(headers.length)].map(
                                        (e, i) => (
                                          <td
                                            key={`td-${i}`}
                                            className='whitespace-nowrap py-4 px-4 text-sm text-gray-500'
                                          >
                                            <Skeleton />
                                          </td>
                                        ),
                                      )}
                                    </tr>
                                  ))
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {state.showBulkUpload && (
                    <>
                      <div className='py-5 px-3 flex items-center justify-between gap-4'>
                        <div className='text-base font-medium text-gray-500'>
                          Total Time Tracked
                        </div>
                        <div className='text-sm text-gray-900'>
                          {state.showTimeInHrMin
                            ? minutesToHour(state.totalTimeTracked)
                            : state.totalTimeTracked + ' mins'}
                        </div>
                      </div>
                      <div className='flex items-center justify-end gap-6'>
                        <Button name='Save' onClick={handleBulkTracking} />
                        <Button
                          name='Cancel'
                          onClick={() => showCancelModal('bulkTracking')}
                          className='w-fit inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2 px-4 text-base bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:w-32 sm:text-sm'
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default TimeTrackingModal;
