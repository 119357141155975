import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IUser {
  isAuthenticated: boolean;
  token: string;
}

const initialState: IUser = {
  isAuthenticated: false,
  token: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<{ token: string }>) => {
      state.isAuthenticated = true;
      state.token = action.payload.token;
    },
    logout: (state) => {
      state.isAuthenticated = false;
    },
    checkToken: (state, action: PayloadAction<{ token: string }>) => {
      if (action.payload.token === 'PRESENT') {
        state.isAuthenticated = true;
      }
    },
  },
});

export const { login, logout, checkToken } = authSlice.actions;
export default authSlice.reducer;
