import { useDispatch, useSelector } from 'react-redux';
import agent from '../../../agent';
import { RootState } from '../../../store/store';
import { useEffect, useState } from 'react';
import Pagination from '../../../components/Pagination';
import Skeleton from 'react-loading-skeleton';
import TextInput from '../../../components/TextInput';
import Button from '../../../components/Button';
import MultiSelectCheckbox from '../../../components/MultiSelectCheckbox';
import { onNotify } from '../../../store/reducers';
import MultiSelect from '../../../components/MultiSelect';
import clientNameFunction from '../../../helpers/clientNameFunction';
import { downloadFile } from '../../../helpers/downloadFile';
import { formatDateTimeString } from '../../../helpers/formatDate';
import MultiSelectWithoutDropdown from '../../../components/MultiSelectWithoutDropdown';
import TableMultiSelect from '../../../components/TableMultiSelect';
interface Istate {
  loading: boolean;
  exporting: boolean;
  tasks: any[];
  selectedTasks: any[];
  totalRecords: number;
  dateFrom: string;
  dateTo: string;
  clients: any[];
  selectedClients: [any];
  billTypes: any[];
  filterSelectedBilltypes: any[];
  selectedBillType: { name: string; _id: string };
  taskStatusTypes: { name: string; _id: string }[];
  selectedTaskStatusTypes: { name: string; _id: string }[];
  skip: number;
  currPage: number;
  chunkSize: number;
}

const initialState: Istate = {
  loading: false,
  exporting: false,
  tasks: [],
  selectedTasks: [],
  totalRecords: 0,
  dateFrom: '',
  dateTo: '',
  clients: [],
  selectedClients: [{ _id: '', name: '' }],
  billTypes: [
    { name: 'All', _id: 'All' },
    { name: 'Billed', _id: 'Billed' },
    { name: 'Not Billed', _id: 'Not Billed' },
    { name: 'Not Billable', _id: 'Not Billable' },
    { name: 'Amount Received', _id: 'Amount Received' },
  ],
  filterSelectedBilltypes: [
    { name: 'All', _id: 'All' },
    { name: 'Billed', _id: 'Billed' },
    { name: 'Not Billed', _id: 'Not Billed' },
    { name: 'Not Billable', _id: 'Not Billable' },
    { name: 'Amount Received', _id: 'Amount Received' },
  ],
  selectedBillType: { name: '', _id: '' },
  taskStatusTypes: [
    { name: 'All', _id: 'All' },
    { name: 'Completed', _id: 'Completed' },
    { name: 'Pending', _id: 'Pending' },
    { name: 'Ignore Tracking', _id: 'Ignore Tracking' },
    { name: 'Not Required', _id: 'Not Required' },
  ],
  selectedTaskStatusTypes: [{ name: 'Completed', _id: 'Completed' }],
  skip: 0,
  currPage: 0,
  chunkSize: 25,
};

const AdvancFeesTrackingReport = () => {
  const commonState = useSelector((state: RootState) => state.common);
  const [state, setState] = useState<Istate>(initialState);

  const dispatch = useDispatch();

  const getAllClients = () => {
    setState((state) => ({ ...state, loading: true }));

    agent.Clients.getClientListForDropdown(
      commonState?.currentFirm?._id as string,
    )
      .then((res: any) => {
        setState((state) => ({
          ...state,
          clients: [{ _id: 'allClients', name: 'All' }, ...res.clients],
          loading: false,
        }));
      })
      .catch(() => {
        setState((state) => ({ ...state, loading: false }));
        dispatch(
          onNotify({
            title: 'Error',
            message: 'Error while fetching clients',
            type: 'danger',
          }),
        );
      });
  };

  const getAdvanceFeesTrackingReport = (download?: boolean) => {
    const workSpaceId = commonState?.currentFirm?._id;

    const skip: number = state.chunkSize * state.currPage;

    const {
      selectedClients,
      billTypes,
      filterSelectedBilltypes,
      taskStatusTypes,
      selectedTaskStatusTypes,
    } = state;

    //check of all selected or not
    const allClientsSelected = selectedClients[0]?._id === '';

    const allBillTypeSelected =
      filterSelectedBilltypes[0]?._id === 'All' ||
      filterSelectedBilltypes?.length === billTypes?.length;

    const allTaskStatusSelected =
      selectedTaskStatusTypes[0]?._id === 'ALL' ||
      selectedTaskStatusTypes?.length === taskStatusTypes?.length;

    //map out the ids
    const clientIds = allClientsSelected
      ? []
      : selectedClients.map((item: any) => item._id);

    const billTypeIds = allBillTypeSelected
      ? []
      : filterSelectedBilltypes.map((item: any) => item._id);

    const taskStausMapped = allTaskStatusSelected
      ? []
      : selectedTaskStatusTypes.map((item: any) => item._id);

    setState((prevState) => ({
      ...prevState,
      loading: true,
      exporting: download ? true : false,
    }));
    agent.FeesTracking.getFeesTrackingReport(
      workSpaceId,
      state.dateFrom,
      state.dateTo,
      clientIds,
      billTypeIds,
      taskStausMapped,
      download ? true : false,
      skip,
      state.chunkSize,
    )
      .then((res: any) => {
        if (res) {
          setState((prevState) => ({
            ...prevState,
            loading: false,
            exporting: false,
          }));
          if (download) {
            return downloadFile(
              res,
              `Finexo PMS-Advance Fees Tracking Report-${formatDateTimeString(
                new Date(),
              )}.xlsx`,
            );
          } else {
            setState((prevState) => ({
              ...prevState,
              tasks: res.tasks,
              totalRecords: res.count,
            }));
          }
        }
      })
      .catch((err: any) => {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          exporting: false,
        }));
        dispatch(
          onNotify({
            title: 'Error',
            message:
              typeof err?.response?.data?.message === 'object'
                ? 'Error while fetching report'
                : err?.response?.data?.message ||
                  err?.response?.data?.error ||
                  err?.message,
            type: 'danger',
          }),
        );
      });
  };

  const onSelectAllTask = () => {
    const { tasks, selectedTasks } = state;
    if (selectedTasks.length === tasks.length) {
      setState((prev) => ({ ...prev, selectedTasks: [] }));
    } else {
      setState((prev) => ({ ...prev, selectedTasks: tasks }));
    }
  };

  const onTaskCheckBoxChange = (task: any) => {
    const { selectedTasks } = state;
    const clientIndex = selectedTasks.findIndex((c: any) => c._id === task._id);
    if (clientIndex === -1) {
      setState((prev) => ({
        ...prev,
        selectedTasks: [...selectedTasks, task],
      }));
    } else {
      const removeClient = selectedTasks.filter((c: any) => c._id !== task._id);
      setState((prev) => ({ ...prev, selectedTasks: removeClient }));
    }
  };

  const onCancelSelection = () => {
    setState((prev) => ({ ...prev, selectedTasks: [] }));
  };

  const changeBillType = (billType: any) => {
    setState((prevState) => ({ ...prevState, selectedBillType: billType }));
  };

  const changeFeesTrackingStatus = () => {
    if (!commonState?.rights?.feesTracking) {
      dispatch(
        onNotify({
          title: 'Rights Not Available',
          message: 'Ask Admin to change your user rights.',
          type: 'danger',
        }),
      );
      return;
    }
    const workSpaceId = commonState?.currentFirm?._id;
    const taskIds = state.selectedTasks.map((task) => task._id);
    const status = state.selectedBillType.name;

    // If status is not selected show error
    if (status === '') {
      dispatch(
        onNotify({
          type: 'danger',
          title: 'Status cannot be empty',
          message: 'Please select status first.',
        }),
      );
      return;
    }

    agent.Tasks.changeFeesTrackingStatus(workSpaceId, taskIds, status)
      .then((res: any) => {
        getAdvanceFeesTrackingReport();
        setState((prev) => ({ ...prev, selectedTasks: [] }));

        dispatch(
          onNotify({
            type: 'success',
            title: 'Bill status changed successfully',
            message: 'Selected Bill status changed successfully.',
          }),
        );
      })
      .catch((err: any) => {
        dispatch(
          onNotify({
            type: 'danger',
            title: 'Could not change bill status',
            message: err?.response?.data?.message || err?.message || err,
          }),
        );
      });
  };

  const changeFeesTrackingStatusInOneTask = (taskId, status) => {
    if (!commonState?.rights?.feesTracking) {
      dispatch(
        onNotify({
          title: 'Rights Not Available',
          message: 'Ask Admin to change your user rights.',
          type: 'danger',
        }),
      );
      return;
    }
    const workSpaceId = commonState?.currentFirm?._id;

    agent.Tasks.changeFeesTrackingStatus(workSpaceId, [taskId], status)
      .then((res: any) => {
        getAdvanceFeesTrackingReport();

        dispatch(
          onNotify({
            type: 'success',
            title: 'Bill status changed successfully',
            message: 'Selected Bill status changed successfully.',
          }),
        );
      })
      .catch((err: any) => {
        dispatch(
          onNotify({
            type: 'danger',
            title: 'Could not change bill status',
            message: err?.response?.data?.message || err?.message || err,
          }),
        );
      });
  };

  const handlePageClick = (data: any) => {
    setState((prevState: any) => ({
      ...prevState,
      currPage: data.selected,
    }));
  };

  const handleItemPerPage = (value: any) => {
    setState((prevState: any) => ({
      ...prevState,
      chunkSize: value.name,
      currPage: 0,
    }));
  };

  const generateReport = () => {
    setState((state) => ({ ...state, currPage: 0 }));
    getAdvanceFeesTrackingReport();
  };

  const exportReport = (download?: boolean) => {
    //prevent exporting if total records is over 10k
    if (state.totalRecords > 10000) {
      dispatch(
        onNotify({
          title: 'Error',
          message:
            'A maximum of 10,000 records can be exported at one time. Please adjust the report criteria to ensure the number of records is below this limit, and then proceed with the export.',
          type: 'danger',
        }),
      );
      return;
    }

    setState((state) => ({ ...state, exporting: download }));
    //download file
    getAdvanceFeesTrackingReport(download);
  };

  const handleDateFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((state) => ({ ...state, dateFrom: e.target.value }));
  };

  const handleDateToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((state) => ({ ...state, dateTo: e.target.value }));
  };

  const handleClientChange = (selectedClient: any) => {
    setState((state) => ({
      ...state,
      selectedClients: [selectedClient],
    }));
  };

  const handleBillChange = (selectedBill: any) => {
    const { billTypes, filterSelectedBilltypes } = state;
    const billNotAlreadySelected =
      filterSelectedBilltypes.findIndex(
        (item: any) => item.name === selectedBill.name,
      ) === -1;
    const allBillTypesSelected =
      filterSelectedBilltypes.findIndex((item: any) => item.name === 'All') !==
      -1;

    if (billNotAlreadySelected) {
      if (
        selectedBill.name === 'All' ||
        filterSelectedBilltypes.length === billTypes.length - 1
      ) {
        setState((state) => ({ ...state, filterSelectedBilltypes: billTypes }));
      } else {
        setState((state) => ({
          ...state,
          filterSelectedBilltypes: [...filterSelectedBilltypes, selectedBill],
        }));
      }
    } else {
      if (selectedBill.name === 'All') {
        setState((state) => ({ ...state, filterSelectedBilltypes: [] }));
      } else if (
        allBillTypesSelected &&
        selectedBill.name !== 'All' &&
        filterSelectedBilltypes.length === billTypes.length
      ) {
        setState((state) => ({
          ...state,
          filterSelectedBilltypes: filterSelectedBilltypes.filter(
            (item: any) =>
              item.name !== 'All' && item.name !== selectedBill.name,
          ),
        }));
      } else {
        setState((state) => ({
          ...state,

          filterSelectedBilltypes: filterSelectedBilltypes.filter(
            (item: any) => item.name !== selectedBill.name,
          ),
        }));
      }
    }
  };

  const handleTaskStatusFilterChange = (selectedTaskStatus: {
    name: string;
    _id: string;
  }) => {
    const { taskStatusTypes, selectedTaskStatusTypes } = state;

    const taskStatusNotAlreadySelected =
      selectedTaskStatusTypes.findIndex(
        (item: any) => item._id === selectedTaskStatus._id,
      ) === -1;
    const allTaskStatusSelected =
      selectedTaskStatusTypes.findIndex((item: any) => item.name === 'ALL') !==
      -1;

    if (taskStatusNotAlreadySelected) {
      if (
        selectedTaskStatus.name === 'All' ||
        selectedTaskStatusTypes.length === taskStatusTypes.length - 1
      ) {
        setState((state) => ({
          ...state,
          selectedTaskStatusTypes: taskStatusTypes,
        }));
      } else {
        setState((state) => ({
          ...state,
          selectedTaskStatusTypes: [
            ...selectedTaskStatusTypes,
            selectedTaskStatus,
          ],
        }));
      }
    } else {
      if (selectedTaskStatus.name === 'All') {
        setState((state) => ({ ...state, selectedTaskStatusTypes: [] }));
      } else if (
        allTaskStatusSelected &&
        selectedTaskStatus.name !== 'All' &&
        selectedTaskStatusTypes.length === taskStatusTypes.length
      ) {
        setState((state) => ({
          ...state,
          selectedTaskStatusTypes: selectedTaskStatusTypes.filter(
            (item: any) =>
              item.name !== 'All' && item.name !== selectedTaskStatus.name,
          ),
        }));
      } else {
        setState((state) => ({
          ...state,
          selectedTaskStatusTypes: selectedTaskStatusTypes.filter(
            (item: any) => item.name !== selectedTaskStatus.name,
          ),
        }));
      }
    }
  };

  const [initialLoad, setInitialLoad] = useState(false);

  useEffect(() => {
    getAllClients();
    getAdvanceFeesTrackingReport();
    setTimeout(() => {
      setInitialLoad(true);
    }, 1000);
  }, []);

  useEffect(() => {
    if (!initialLoad) return;
    getAdvanceFeesTrackingReport();
  }, [state.skip, state.chunkSize, state.currPage]);

  //updates report list on firm change
  useEffect(() => {
    if (!initialLoad) return;
    if (commonState.currentFirm?._id) {
      getAdvanceFeesTrackingReport();
    }
  }, [commonState?.currentFirm?._id]);

  //change page number on pressing left or right keys
  useEffect(() => {
    const handleKeyLeftOrRight = (e: KeyboardEvent) => {
      if (
        commonState?.searchNavigation ||
        commonState?.currentModal?.modalName === 'TIME_TRACKING_MODAL'
      ) {
        return;
      }

      if (e.key === 'ArrowRight') {
        // return if already on last page
        if (
          state.currPage + 1 ===
          Math.ceil(state.totalRecords / state.chunkSize)
        ) {
          return;
        }
        setState((prevState) => ({
          ...prevState,
          currPage: state.currPage + 1,
          skip: (state.currPage + 1) * state.chunkSize,
          limit: state.chunkSize,
        }));
      }
      // previous page on pressing left button
      if (e.key === 'ArrowLeft') {
        // return if already on first page
        if (state.currPage === 0) {
          return;
        }
        setState((prevState) => ({
          ...prevState,
          currPage: state.currPage - 1,
          skip: (state.currPage - 1) * state.chunkSize,
          limit: state.chunkSize,
        }));
      }
    };

    window.addEventListener('keydown', handleKeyLeftOrRight);
    return () => window.removeEventListener('keydown', handleKeyLeftOrRight);
  }, [
    state,
    commonState?.searchNavigation,
    commonState?.currentModal?.modalName,
  ]);

  return (
    <div className='max-w-8xl mx-auto'>
      <h1 className='text-2xl font-semibold text-gray-900 capitalize mb-10'>
        Your Advance Fees Tracking Report
      </h1>
      <div className='space-y-6 px-6 my-6 bg-white shadow rounded-md'>
        <dl className='divide-y-2 divide-gray-200'>
          <div className='py-5 sm:grid sm:grid-cols-3 sm:gap-4 items-center'>
            <dt className='text-base font-medium text-gray-700'>
              Select Date of Task Created
            </dt>
            <dd className='mt-1 text-sm text-gray-900 sm:mt-0 grid grid-cols-2 gap-6'>
              <div>
                <label
                  htmlFor='start-time'
                  className='block text-sm font-medium text-gray-700 mb-2'
                >
                  Date From
                </label>
                <TextInput
                  name='start-time'
                  id='start-time'
                  type='date'
                  max={state.dateTo}
                  value={state.dateFrom}
                  onChange={handleDateFromChange}
                />
              </div>
              <div>
                <label
                  htmlFor='stop-time'
                  className='block text-sm font-medium text-gray-700 mb-2'
                >
                  Date To
                </label>
                <TextInput
                  name='stop-time'
                  id='stop-time'
                  type='date'
                  min={state.dateFrom}
                  value={state.dateTo}
                  onChange={handleDateToChange}
                />
              </div>
            </dd>
          </div>

          {/* select client*/}
          <div className='py-5 sm:grid sm:grid-cols-3 sm:gap-4'>
            <dt className='text-base font-medium text-gray-700'>
              Select Client
            </dt>
            <dd className='mt-1 text-sm text-gray-900 sm:mt-0'>
              <MultiSelectWithoutDropdown
                items={state.clients.map((client) => ({
                  ...client,
                  name: client.name,
                  _id: client._id,
                }))}
                type='client'
                placeholder='Select Client otherwise all clients will be considered'
                selected={{ name: state.selectedClients[0]?.name }}
                onChange={handleClientChange}
                disabled={false}
              />
            </dd>
          </div>

          {/* select bill type*/}
          <div className='py-5 sm:grid sm:grid-cols-3 sm:gap-4'>
            <dt className='text-base font-medium text-gray-700'>
              Select Fees Tracking Status
            </dt>
            <dd className='mt-1 text-sm text-gray-900 sm:mt-0'>
              <MultiSelectCheckbox
                type='bill-type-filter'
                placeholder='Select Fees Tracking Status'
                items={state.billTypes.map((bill) => ({
                  ...bill,
                  name: bill.name,
                }))}
                selected={state.filterSelectedBilltypes.map((bill) => ({
                  ...bill,
                  name: bill.name,
                }))}
                onChange={handleBillChange}
              />
            </dd>
          </div>

          <div className='py-5 sm:grid sm:grid-cols-3 sm:gap-4'>
            <dt className='text-base font-medium text-gray-700'>
              Select Task Status
            </dt>
            <dd className='mt-1 text-sm text-gray-900 sm:mt-0'>
              <MultiSelectCheckbox
                type='task-status-type-filter'
                placeholder='Select Status'
                items={state.taskStatusTypes.map((taskStatus) => ({
                  ...taskStatus,
                  name: taskStatus.name,
                  _id: taskStatus._id,
                }))}
                selected={state.selectedTaskStatusTypes.map((taskStatus) => ({
                  ...taskStatus,
                  name: taskStatus.name,
                  _id: taskStatus._id,
                }))}
                onChange={handleTaskStatusFilterChange}
              />
            </dd>
          </div>

          <div className='py-5 sm:grid sm:grid-cols-3 sm:gap-4'>
            <dt className='text-base font-medium text-gray-700'></dt>
            <dd className='w-full mt-1 text-sm text-gray-900 sm:mt-0 flex items-center gap-8 justify-center'>
              <Button
                type='link'
                to={`/${commonState?.currentFirm?._id}/reports/list`}
                name='Cancel'
                // onClick={onCancel}
                className='w-32 inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2 px-4 text-base bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:text-sm'
              />
              <Button
                name='Filter'
                onClick={generateReport}
                icon='document-report'
                disabled={state.loading}
              />
            </dd>
          </div>
        </dl>
      </div>

      {state.tasks.length > 0 ? (
        <div className='my-6 flex items-center gap-8 justify-between'>
          <div className='ml-auto'>
            <Button
              name='Export'
              icon={state.exporting ? 'loading' : 'outline/download'}
              onClick={() => exportReport(true)}
            />
          </div>
        </div>
      ) : null}

      <>
        {!state.loading ? (
          Object.entries(state.tasks).length > 0 ? (
            <div className='rounded-md sm:overflow-hidden'>
              <div className='flex flex-col'>
                <div id='tab-scroll' className='overflow-x-auto'>
                  <div className='inline-block min-w-full align-middle'>
                    {/* show cancel or save on task selection*/}
                    {state.selectedTasks.length !== 0 && (
                      <div className='w-full mb-4 text-sm text-gray-900 flex items-center gap-4 justify-end pr-5'>
                        <div className='min-w-48 font-bold'>
                          <MultiSelect
                            items={state.billTypes.slice(
                              1,
                              state.billTypes.length,
                            )}
                            type='billable-status'
                            selected={state.selectedBillType}
                            onChange={changeBillType}
                            placeholder='Select Fees Tracking Status'
                          />
                        </div>
                        <Button
                          type='button'
                          name='Change Status'
                          onClick={changeFeesTrackingStatus}
                          noMr
                        />
                        <Button
                          type='button'
                          name='Cancel'
                          onClick={onCancelSelection}
                          className='w-28 inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2 px-4 pl-0 text-base bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:text-sm'
                        />
                      </div>
                    )}

                    <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                      {/*table*/}
                      <table className='min-w-full border-collapse border shadow-sm'>
                        <thead className='bg-gray-50'>
                          <tr>
                            <th
                              style={{ zIndex: 8 }}
                              scope='col'
                              className='sticky top-0 whitespace-nowrap border-b border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-4'
                            >
                              <div className='flex items-center gap-4'>
                                <div>
                                  <input
                                    type='checkbox'
                                    className='h-4 w-4 rounded border-gray-400 text-indigo-600 focus:ring-indigo-500'
                                    checked={
                                      state.tasks.length > 0 &&
                                      state.tasks.length ===
                                        state.selectedTasks.length
                                    }
                                    onChange={onSelectAllTask}
                                  />
                                </div>
                              </div>
                            </th>

                            <th
                              style={{ zIndex: 6 }}
                              scope='col'
                              className=' border-l  sticky top-0 border-b border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-6'
                            >
                              Client
                            </th>
                            <th
                              style={{ zIndex: 6 }}
                              scope='col'
                              className='sticky top-0 border-b border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-6'
                            >
                              Task
                            </th>
                            <th
                              style={{ zIndex: 6 }}
                              scope='col'
                              className='sticky top-0 border-b border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-6'
                            >
                              Task Period
                            </th>
                            <th
                              style={{ zIndex: 6 }}
                              scope='col'
                              className='sticky top-0 border-b border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-6'
                            >
                              Task Status
                            </th>
                            <th
                              style={{ zIndex: 6 }}
                              scope='col'
                              className='sticky top-0 border-b border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-6'
                            >
                              Fees Status
                            </th>
                          </tr>
                        </thead>
                        {state.totalRecords === 0 ? (
                          <tbody>
                            <tr>
                              <td className='px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900'>
                                No record of non billed tasks
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody className='bg-white'>
                            {state.tasks?.map((task: any, index: any) => {
                              return (
                                <tr
                                  key={index}
                                  className={
                                    index % 2 === 0 ? undefined : 'bg-gray-100'
                                  }
                                >
                                  <td className='w-1 whitespace-nowrap py-4 pl-4 pr-3 font-bold text-sm text-gray-900 sm:pl-4'>
                                    <div className='flex items-center gap-3'>
                                      <div>
                                        <input
                                          type='checkbox'
                                          className='h-4 w-4 rounded border-gray-400 text-indigo-600 focus:ring-indigo-500'
                                          checked={state.selectedTasks.some(
                                            (item: any) =>
                                              item._id === task._id,
                                          )}
                                          onChange={() =>
                                            onTaskCheckBoxChange(task)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </td>

                                  <td className='max-w-[20ch] border-l border-gray-300 whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6'>
                                    <p className=' font-bold truncate'>
                                      {clientNameFunction(task)}
                                    </p>
                                  </td>
                                  <td className='max-w-[20ch] whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6'>
                                    <p className='truncate'>{task.name}</p>
                                  </td>
                                  <td className='max-w-[20ch] whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6'>
                                    <p className='truncate'>{task.period}</p>
                                  </td>
                                  <td className='max-w-[20ch] whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6'>
                                    <p className='truncate'>
                                      {task.statusName}
                                    </p>
                                  </td>
                                  <td className='max-w-[20ch] whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6'>
                                    <div className='truncate'>
                                      <TableMultiSelect
                                        items={state.billTypes.slice(
                                          1,
                                          state.billTypes.length,
                                        )}
                                        type='task-status'
                                        selected={{
                                          name: task.feesStatus,
                                        }}
                                        onChange={(selectedBill: any) => {
                                          if (
                                            task.feesStatus ===
                                            selectedBill.name
                                          ) {
                                            return;
                                          }
                                          changeFeesTrackingStatusInOneTask(
                                            task._id,
                                            selectedBill.name,
                                          );
                                        }}
                                        placeholder='Select New Bill Status'
                                      />
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        )}
                      </table>

                      <Pagination
                        displayRecords={state.tasks}
                        totalRecords={state.totalRecords}
                        currPage={state.currPage}
                        chunkSize={state.chunkSize}
                        handlePageClick={handlePageClick}
                        handleItemPerPage={handleItemPerPage}
                        className='my-4'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='bg-white px-4 py-4 mb-12 whitespace-nowrap text-sm text-gray-900 font-semibold capitalize'>
              There are no records to show
            </div>
          )
        ) : (
          [...Array(5)].map((e, i) => (
            <div
              key={`div-${i}`}
              className='bg-white grid'
              style={{
                gridTemplateColumns: `repeat(8, minmax(0, 1fr))`,
              }}
            >
              {[...Array(8)].map((e, i) => (
                <div
                  key={`div-${i}`}
                  className='px-4 py-3 whitespace-wrap text-sm font-medium text-gray-900'
                >
                  <Skeleton />
                </div>
              ))}
            </div>
          ))
        )}
      </>
    </div>
  );
};

export default AdvancFeesTrackingReport;
