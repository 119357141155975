import { createSlice } from '@reduxjs/toolkit';

type InitialState = {
  showMaintenance?: boolean;
  maintenanceMessage?: string;
};

const initialState: InitialState = {};

const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState,
  reducers: {
    updateMaintenanceMode: (state, action) => {
      state.showMaintenance = action.payload;
    },
    updateMaintenanceMessage: (state, action) => {
      state.maintenanceMessage = action.payload;
    },
  },
});

export const { updateMaintenanceMode, updateMaintenanceMessage } =
  appConfigSlice.actions;
export default appConfigSlice.reducer;
