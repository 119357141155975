import ReactPaginate from 'react-paginate';
import { useLocation } from 'react-router-dom';
import { itemsPerPage } from '../constants/itemsPerPage';
import SelectMenu from './SelectMenu';
import TextInput from './TextInput';
import Button from './Button';
import { useState } from 'react';
import { onNotify } from '../store/reducers';
import { useDispatch } from 'react-redux';

interface Props {
  displayRecords: any[];
  totalRecords: number;
  currPage: number;
  chunkSize: number;
  hidechunkSizeSelector?: boolean;
  handleItemPerPage?: (value: { name: number }) => void;
  handlePageClick: (data: { selected: number }) => void;
  forcePage?: number;
  className?: string;
  page?: string;
}

export default function Pagination(props: Props) {
  const location = useLocation();
  const pathname = location.pathname;
  const dispatch = useDispatch();

  const [goToPage, setGoToPage] = useState('');

  const handleGotoPageClick = (pageNo: number) => {
    if (pageNo <= 0) {
      dispatch(
        onNotify({
          title: 'Error',
          message: 'Page No. cannot be negative or zero!',
          type: 'danger',
        }),
      );
      setGoToPage('');
      return;
    }

    if (pageNo % 1 !== 0) {
      dispatch(
        onNotify({
          title: 'Error',
          message: 'Page No. cannot be a decimal number!',
          type: 'danger',
        }),
      );
      setGoToPage('');
      return;
    }

    const totalPages = Math.ceil(props.totalRecords / props.chunkSize);
    if (pageNo > totalPages) {
      dispatch(
        onNotify({
          title: 'Error',
          message: `Page number ${pageNo} does not exist. There are only ${totalPages} pages.`,
          type: 'danger',
        }),
      );
      setGoToPage('');
      return;
    }

    props.handlePageClick({ selected: pageNo - 1 });
  };

  const handleGoToPageKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleGotoPageClick(Number(goToPage));
    }
  };

  return props.displayRecords.length > 0 ? (
    <div
      className={`bg-white px-3 md:px-3 py-3 flex gap-y-2 md:gap-2 flex-col sm:flex-row items-center justify-between border border-gray-200 shadow-sm ${
        pathname.includes('task') ||
        pathname.includes('import') ||
        pathname.includes('messages') ||
        pathname.includes('reports') ||
        pathname.includes('client-profile') ||
        pathname.includes('group-profile') ||
        pathname.includes('support') ||
        pathname.includes('bulk-edit')
          ? ''
          : 'lg:mx-8'
      } ${props.className ?? ''}`}
    >
      <div className='grow flex gap-4 sm:gap-2 md:gap-4 flex-col sm:flex-row items-center justify-between'>
        <p className='text-sm text-gray-700 order-last sm:order-first'>
          Showing{' '}
          <span className='font-medium'>
            {props.currPage * props.chunkSize + 1}
          </span>{' '}
          to{' '}
          <span className='font-medium'>
            {(props.currPage + 1) * props.chunkSize > props.totalRecords
              ? props.totalRecords
              : (props.currPage + 1) * props.chunkSize}
          </span>{' '}
          of <span className='font-medium'>{props.totalRecords}</span> results
        </p>

        {!props.hidechunkSizeSelector && (
          <div className='flex items-center gap-2'>
            <div className='hidden sm:flex mt-1 w-56 gap-2 whitespace-nowrap'>
              <TextInput
                type='number'
                name=''
                value={goToPage && goToPage.toString()}
                onChange={(e) => setGoToPage(e.target.value)}
                placeholder='Page No.'
                onKeyDown={handleGoToPageKeyDown}
              />

              <div className='h-10 mt-1'>
                <Button
                  name='Go to Page'
                  onClick={() => handleGotoPageClick(Number(goToPage))}
                  disabled={!goToPage}
                />
              </div>
            </div>
            <SelectMenu
              label='Items per page'
              items={
                props.page !== 'addTask'
                  ? itemsPerPage
                  : [{ name: 10 }, { name: 25 }, { name: 50 }]
              }
              selected={{ name: props.chunkSize }}
              onChange={props.handleItemPerPage ?? (() => {})}
            />
          </div>
        )}
      </div>
      <div id='pagination' className='text-sm text-gray-500 my-2'>
        <ReactPaginate
          previousLabel={'Prev'}
          nextLabel={'Next'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={Math.ceil(props.totalRecords / props.chunkSize)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={2}
          onPageChange={props.handlePageClick}
          containerClassName={'pagination'}
          activeClassName={'active'}
          forcePage={props.currPage}
        />
      </div>
    </div>
  ) : null;
}
