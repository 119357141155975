const AgencyClients: string = '/dashboard';

const routes = {
  AgencyClients,
};

// url on which side menu and top menu will be hidden. subsstring of 18 characters is used only.
export const BLACKLISTED_ROUTES = [
  '/',
  '/signup',
  '/sign-up-completed',
  '/forgetPassword',
  '/reset-password',
  '/verify/reset/otp/',
  '/verify/signup/otp',
  '/verify/signup/tok',
  '/verify/reset/toke',
];

export default routes;
