import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { createRef, useEffect, useState } from 'react';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import parse from 'html-react-parser';

interface Props {
  content: any;
}

export default function ToolTip(props: Props) {
  return (
    <Popup
      className='w-fit max-w-full inline-block align-middle items-center pl-1'
      content={props.content}
    >
      <InformationCircleIcon
        className='h-5 w-5 text-gray-500'
        aria-hidden='true'
      />
    </Popup>
  );
}

interface PopupProps {
  content: any;
  children: any;
  className?: string;
  capitalize?: boolean;
}

interface IState {
  isPopoverOpen: boolean;
  popupArrowColor: string;
  typingTimeout: any;
}

const initialState: IState = {
  isPopoverOpen: false,
  popupArrowColor: '',
  typingTimeout: 0,
};

function Popup(props: PopupProps) {
  const popupRef = createRef<HTMLDivElement>();
  const [state, setState] = useState<IState>(initialState);

  useEffect(() => {
    if (popupRef.current) {
      setState((prevState: any) => ({
        ...prevState,
        popupArrowColor: window.getComputedStyle(popupRef.current as Element)
          .backgroundColor,
      }));
    }
  }, [state.isPopoverOpen]);

  function onMouseLeave() {
    let timer: any = null;

    timer = setTimeout(() => {
      setState((prevState: any) => ({
        ...prevState,
        isPopoverOpen: false,
      }));
    }, 200);
    setState((prevState: any) => ({
      ...prevState,
      typingTimeout: timer,
    }));
    return () => clearTimeout(timer);
  }

  function onMouseOver() {
    setState((prevState: any) => ({
      ...prevState,
      isPopoverOpen: true,
    }));
    clearTimeout(state.typingTimeout);
  }

  return !props.content ||
    props.content === '' ||
    props.content === ' ' ||
    props.content === '-' ? (
    <div className={props.className}>{props.children}</div>
  ) : (
    <div
      className={props.className}
      onMouseOver={() => onMouseOver()}
      onMouseLeave={() => onMouseLeave()}
      key={props.content}
    >
      <Popover
        isOpen={state.isPopoverOpen}
        positions={['top', 'bottom', 'left', 'right']} // preferred positions by priority
        padding={4}
        containerStyle={{ zIndex: '9999' }}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer // for showing the arrow below the popover
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor={'#CFD0D5'}
            arrowSize={7}
            className='popover-arrow-container'
            arrowClassName='popover-arrow '
          >
            <div
              ref={popupRef}
              className={`bg-white shadow text-gray-900 border border-gray-300 rounded-md px-2 py-2 text-sm whitespace-pre-line max-w-sm break-words ${
                props.capitalize && 'capitalize'
              }`}
            >
              {parse(props.content)}
            </div>
          </ArrowContainer>
        )}
      >
        {props.children}
      </Popover>
    </div>
  );
}
