import { configureStore } from '@reduxjs/toolkit';
import createLogger from 'redux-logger';
import authMiddleware from './middlewares/auth';
import appConfig from './reducers/appConfig';
import common from './reducers/common';
import gsts from './reducers/gsts';
import notification from './reducers/notification';
import user from './reducers/userAuth';

const store = configureStore({
  reducer: {
    common,
    user,
    gsts,
    notification,
    appConfig,
  },
  middleware: (getDefaultMiddleware) => {
    if (
      import.meta.env?.VITE_NODE_ENV === 'production' ||
      import.meta.env?.VITE_NODE_ENV === 'staging'
    ) {
      return getDefaultMiddleware({ serializableCheck: false }).concat(
        authMiddleware,
      );
    } else {
      return getDefaultMiddleware({ serializableCheck: false }).concat(
        // createLogger,
        authMiddleware,
      );
    }
  },
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
