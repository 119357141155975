import { Dialog, Transition } from '@headlessui/react';
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import agent from '../agent';
import { onNotify } from '../store/reducers';
import { RootState } from '../store/store';
import Icon from './Icon';

type Props = {
  onLoad?: (forSearch: boolean) => void;
  deleteModalSetOpen?: (open: boolean) => void;
  state?: any;
  type?: any;
};

interface IState {
  logging: boolean;
}

const initialState: IState = {
  logging: false,
};

export default function DeleteModal(props: Props) {
  const commonState = useSelector((state: RootState) => state.common);
  const workSpaceId = commonState.currentFirm?._id;
  const dispatch = useDispatch();

  const [state, setState] = useState<IState>(initialState);

  const onKeyUpFunction = (event: any) => {
    if (event.key === 'Escape') {
      setOpen(false);
    }

    if (event.key === 'Enter') {
      deleteIdRow();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyUpFunction, false);

    return () => {
      document.removeEventListener('keydown', onKeyUpFunction, false);
    };
  }, []);

  const deleteTag = () => {
    const id = props.state.selectedRow._id;
    setState({ logging: true });
    agent.Tag.deleteTag(id, workSpaceId)
      .then(() => {
        dispatch(
          onNotify({
            title: 'Success!',
            message: 'Tag Deleted Successfully.',
            type: 'success',
          }),
        );

        setState({ logging: false });
        setOpen(false);
        onLoad();
      })
      .catch((err: any) => {
        setState({ logging: false });
        setOpen(false);

        dispatch(
          onNotify({
            title: 'Error',
            message:
              err?.response?.data?.message ||
              err?.response?.data?.error ||
              err?.message ||
              err,
            type: 'danger',
          }),
        );
      });
  };

  const deleteStatus = () => {
    const id = props.state.selectedRow._id;

    setState({ logging: true });
    agent.Status.deleteStatus(id, workSpaceId)
      .then(() => {
        dispatch(
          onNotify({
            title: 'Success!',
            message: 'Status Deleted Successfully.',
            type: 'success',
          }),
        );

        setState({ logging: false });
        setOpen(false);
        onLoad();
      })
      .catch((err: any) => {
        setState({ logging: false });
        setOpen(false);

        dispatch(
          onNotify({
            title: 'Error',
            message:
              err?.response?.data?.message ||
              err?.response?.data?.error ||
              err?.message ||
              err,
            type: 'danger',
          }),
        );
      });
  };

  const deleteCustomField = () => {
    const customFieldId = props.state.selectedRow._id;
    setState({ logging: true });
    agent.CustomField.deleteCustomField(customFieldId, workSpaceId)
      .then(() => {
        dispatch(
          onNotify({
            title: 'Success!',
            message: 'Custom Field Deleted Successfully.',
            type: 'success',
          }),
        );

        setState({ logging: false });
        setOpen(false);
        onLoad();
      })
      .catch((err: any) => {
        setState({ logging: false });
        setOpen(false);

        dispatch(
          onNotify({
            title: 'Error',
            message:
              err?.response?.data?.message ||
              err?.response?.data?.error ||
              err?.message ||
              err,
            type: 'danger',
          }),
        );
      });
  };

  const deletePerson = () => {
    const contactPersonId = props.state.selectedRow._id;
    setState({ logging: true });
    agent.ContactPerson.deletePersonField(contactPersonId, workSpaceId)
      .then(() => {
        dispatch(
          onNotify({
            title: 'Success!',
            message: 'Contact Person Deleted Successfully.',
            type: 'success',
          }),
        );

        setState({ logging: false });
        setOpen(false);
        onLoad();
      })
      .catch((err: any) => {
        setState({ logging: false });
        setOpen(false);

        dispatch(
          onNotify({
            title: 'Error',
            message:
              err?.response?.data?.message ||
              err?.response?.data?.error ||
              err?.message ||
              err,
            type: 'danger',
          }),
        );
      });
  };

  const deleteGroup = () => {
    const groupId = props.state.selectedRow._id;
    setState({ logging: true });
    agent.ClientGroups.deleteClientGroup(workSpaceId, groupId)
      .then(() => {
        dispatch(
          onNotify({
            title: 'Success!',
            message: 'Group Deleted Successfully.',
            type: 'success',
          }),
        );

        setState({ logging: false });
        setOpen(false);
        onLoad();
      })
      .catch((err: any) => {
        setState({ logging: false });
        setOpen(false);

        dispatch(
          onNotify({
            title: 'Error',
            message:
              err?.response?.data?.message ||
              err?.response?.data?.error ||
              err?.message ||
              err,
            type: 'danger',
          }),
        );
      });
  };

  const deleteClient = () => {
    const clientId = props.state.selectedRow._id;
    setState({ logging: true });
    agent.Clients.deleteClient(workSpaceId, clientId)
      .then(() => {
        dispatch(
          onNotify({
            title: 'Success!',
            message: 'Client Deleted Successfully.',
            type: 'success',
          }),
        );

        setState({ logging: false });
        setOpen(false);
        onLoad();
      })
      .catch((err: any) => {
        setState({ logging: false });

        dispatch(
          onNotify({
            title: 'Error',
            message:
              err?.response?.data?.message ||
              err?.response?.data?.error ||
              err?.message ||
              err,
            type: 'danger',
          }),
        );
      });
  };

  const deleteFirm = () => {
    const workSpaceId = props.state.selectedRow._id;
    setState({ logging: true });
    agent.Firm.deleteFirm(workSpaceId)
      .then(() => {
        dispatch(
          onNotify({
            title: 'Success!',
            message: 'Firm Deleted Successfully.',
            type: 'success',
          }),
        );

        setState({ logging: false });
        setOpen(false);
        onLoad();
      })
      .catch((err: any) => {
        setState({ logging: false });
        setOpen(false);

        dispatch(
          onNotify({
            title: 'Error',
            message:
              err?.response?.data?.message ||
              err?.response?.data?.error ||
              err?.message ||
              err,
            type: 'danger',
          }),
        );
      });
  };

  const deleteUser = () => {
    const userId = props.state.selectedRow._id;
    setState({ logging: true });
    agent.User.removeUser(workSpaceId, userId)
      .then(() => {
        dispatch(
          onNotify({
            title: 'Success!',
            message: 'User Deleted Successfully.',
            type: 'success',
          }),
        );

        setState({ logging: false });
        setOpen(false);
        onLoad();
      })
      .catch((err: any) => {
        setState({ logging: false });
        setOpen(false);

        dispatch(
          onNotify({
            title: 'Error',
            message:
              err?.response?.data?.message ||
              err?.response?.data?.error ||
              err?.message ||
              err,
            type: 'danger',
          }),
        );
      });
  };

  const deleteTodoList = () => {
    const toDoListId = props.state.selectedRow._id;
    setState({ logging: true });
    agent.Todo.deleteList(workSpaceId, toDoListId)
      .then(() => {
        dispatch(
          onNotify({
            title: 'Success!',
            message: 'Todo List Deleted Successfully.',
            type: 'success',
          }),
        );

        setState({ logging: false });
        setOpen(false);
        onLoad();
      })
      .catch((err: any) => {
        setState({ logging: false });
        setOpen(false);

        dispatch(
          onNotify({
            title: 'Error',
            message:
              err?.response?.data?.message ||
              err?.response?.data?.error ||
              err?.message ||
              err,
            type: 'danger',
          }),
        );
      });
  };

  const deleteTodoItem = () => {
    const toDoId = props.state.selectedRow._id;
    setState({ logging: true });
    agent.Todo.delete({ workSpaceId, toDoId })
      .then(() => {
        dispatch(
          onNotify({
            title: 'Success!',
            message: 'Todo Item Deleted Successfully.',
            type: 'success',
          }),
        );

        setState({ logging: false });
        setOpen(false);
        onLoad();
      })
      .catch((err: any) => {
        setState({ logging: false });
        setOpen(false);

        dispatch(
          onNotify({
            title: 'Error',
            message:
              err?.response?.data?.message ||
              err?.response?.data?.error ||
              err?.message ||
              err,
            type: 'danger',
          }),
        );
      });
  };

  const deleteReceiptEntry = () => {
    const receiptId = props.state.selectedRow._id;
    setState({ logging: true });
    agent.Receipt.deleteReceipt(workSpaceId, receiptId)
      .then(() => {
        dispatch(
          onNotify({
            title: 'Success!',
            message: 'Receipt Deleted Successfully.',
            type: 'success',
          }),
        );

        setState({ logging: false });
        setOpen(false);
        onLoad();
      })
      .catch((err: any) => {
        setState({ logging: false });
        setOpen(false);

        dispatch(
          onNotify({
            title: 'Error',
            message:
              err?.response?.data?.message ||
              err?.response?.data?.error ||
              err?.message ||
              err,
            type: 'danger',
          }),
        );
      });
  };

  const deleteComment = () => {
    const commentId = props.state.selectedComment._id;
    setState({ logging: true });
    agent.Tasks.deleteComment(workSpaceId, commentId)
      .then(() => {
        dispatch(
          onNotify({
            title: 'Success!',
            message: 'Comment Deleted Successfully.',
            type: 'success',
          }),
        );

        setState({ logging: false });
        setOpen(false);
        onLoad();
      })
      .catch((err: any) => {
        setState({ logging: false });
        setOpen(false);

        dispatch(
          onNotify({
            title: 'Error',
            message:
              err?.response?.data?.message ||
              err?.response?.data?.error ||
              err?.message ||
              err,
            type: 'danger',
          }),
        );
      });
  };

  const deleteTask = () => {
    const taskId = props.state.selectedTask._id;
    setState({ logging: true });
    agent.Tasks.deleteTask(workSpaceId, [taskId])
      .then(() => {
        dispatch(
          onNotify({
            title: 'Success!',
            message: 'Task Deleted Successfully.',
            type: 'success',
          }),
        );

        setState({ logging: false });
        setOpen(true);
        onLoad();
      })
      .catch((err: any) => {
        setState({ logging: false });
        setOpen(false);

        dispatch(
          onNotify({
            title: 'Error',
            message:
              err?.response?.data?.message ||
              err?.response?.data?.error ||
              err?.message ||
              err,
            type: 'danger',
          }),
        );
      });
  };

  const deleteOtherTask = () => {
    const taskId = props.state.selectedRow._id;
    setState({ logging: true });
    agent.Tasks.deleteOtherTask(workSpaceId, taskId)
      .then(() => {
        dispatch(
          onNotify({
            title: 'Success!',
            message: 'Task Deleted Successfully.',
            type: 'success',
          }),
        );

        setState({ logging: false });
        setOpen(false);
        onLoad();
      })
      .catch((err: any) => {
        setState({ logging: false });
        setOpen(false);

        dispatch(
          onNotify({
            title: 'Error',
            message:
              err?.response?.data?.message ||
              err?.response?.data?.error ||
              err?.message ||
              err,
            type: 'danger',
          }),
        );
      });
  };

  const deleteMessageTemplate = () => {
    const templateId = props.state.selectedRow._id;
    setState({ logging: true });
    agent.Messages.deleteTemplate(workSpaceId, templateId)
      .then(() => {
        dispatch(
          onNotify({
            title: 'Success!',
            message: 'Message Template Deleted Successfully.',
            type: 'success',
          }),
        );

        setState({ logging: false });
        setOpen(false);
        onLoad();
      })
      .catch((err: any) => {
        setState({ logging: false });
        setOpen(false);

        dispatch(
          onNotify({
            title: 'Error',
            message:
              err?.response?.data?.message ||
              err?.response?.data?.error ||
              err?.message ||
              err,
            type: 'danger',
          }),
        );
      });
  };

  const deleteRegisterEntry = () => {
    const registerId = props.state.selectedRow._id;
    setState({ logging: true });
    agent.RegisterInOut.deleteRegisterEntry(registerId, workSpaceId)
      .then(() => {
        dispatch(
          onNotify({
            title: 'Success!',
            message: 'Register Entry Deleted Successfully.',
            type: 'success',
          }),
        );

        setState({ logging: false });
        setOpen(false);
        onLoad();
      })
      .catch((err: any) => {
        setState({ logging: false });
        setOpen(false);

        dispatch(
          onNotify({
            title: 'Error',
            message:
              err?.response?.data?.message ||
              err?.response?.data?.error ||
              err?.message ||
              err,
            type: 'danger',
          }),
        );
      });
  };

  const deleteKeptAt = () => {
    const keptAtId = props.state.selectedRow._id;
    setState({ logging: true });
    agent.RegisterInOut.deleteKeptAt(workSpaceId, keptAtId)
      .then(() => {
        dispatch(
          onNotify({
            title: 'Success!',
            message: 'Kept At Entry Deleted Successfully.',
            type: 'success',
          }),
        );

        setState({ logging: false });
        setOpen(false);
        onLoad();
      })
      .catch((err: any) => {
        setState({ logging: false });
        setOpen(false);

        dispatch(
          onNotify({
            title: 'Error',
            message:
              err?.response?.data?.message ||
              err?.response?.data?.error ||
              err?.message ||
              err,
            type: 'danger',
          }),
        );
      });
  };

  const deleteDocument = () => {
    const documentId = props.state.selectedRow._id;
    agent.RegisterInOut.deleteDocument(workSpaceId, documentId)
      .then(() => {
        dispatch(
          onNotify({
            title: 'Success!',
            message: 'Document Deleted Successfully.',
            type: 'success',
          }),
        );

        setState({ logging: false });
        setOpen(false);
        onLoad();
      })
      .catch((err: any) => {
        setState({ logging: false });
        setOpen(false);

        dispatch(
          onNotify({
            title: 'Error',
            message:
              err?.response?.data?.message ||
              err?.response?.data?.error ||
              err?.message ||
              err,
            type: 'danger',
          }),
        );
      });
  };

  const deleteTracking = () => {
    setState({ logging: true });

    agent.TimeTracking.deleteTimeTracking(
      workSpaceId,
      props.state.selectedRow._id,
    )
      .then(() => {
        setState({ logging: false });
        setOpen(false);
        onLoad();

        dispatch(
          onNotify({
            title: 'Success!',
            message: 'Tracking Deleted Successfully.',
            type: 'success',
          }),
        );
      })
      .catch((err: any) => {
        setState({ logging: false });

        dispatch(
          onNotify({
            title: 'Error',
            message:
              err?.response?.data?.message ||
              err?.response?.data?.error ||
              err?.message ||
              err,
            type: 'danger',
          }),
        );
      });
  };

  const deleteTagFromClient = () => {
    const clientId = props.state.selectedRow._id;
    const itemId = props.state.currentItemId;

    agent.Clients.deleteTag(workSpaceId, clientId, itemId)
      .then(() => {
        dispatch(
          onNotify({
            title: 'Success!',
            message: 'Tag removed from client Successfully.',
            type: 'success',
          }),
        );

        setState({ logging: false });
        setOpen(false);
        onLoad();
      })
      .catch((err: any) => {
        setState({ logging: false });
        setOpen(false);

        dispatch(
          onNotify({
            title: 'Error',
            message:
              err?.response?.data?.message ||
              err?.response?.data?.error ||
              err?.message ||
              err,
            type: 'danger',
          }),
        );
      });
  };

  const deleteGroupFromClient = () => {
    const clientId = props.state.selectedRow._id;
    const itemId = props.state.currentItemId;

    agent.Clients.deleteGroup(workSpaceId, clientId, itemId)
      .then(() => {
        dispatch(
          onNotify({
            title: 'Success!',
            message: 'Group removed from client Successfully.',
            type: 'success',
          }),
        );

        setState({ logging: false });
        setOpen(false);
        onLoad();
      })
      .catch((err: any) => {
        setState({ logging: false });
        setOpen(false);

        dispatch(
          onNotify({
            title: 'Error',
            message:
              err?.response?.data?.message ||
              err?.response?.data?.error ||
              err?.message ||
              err,
            type: 'danger',
          }),
        );
      });
  };

  const deletePersonFromClient = () => {
    const clientId = props.state.selectedRow._id;
    const itemId = props.state.currentItemId;

    agent.Clients.deletePerson(workSpaceId, clientId, itemId)
      .then(() => {
        dispatch(
          onNotify({
            title: 'Success!',
            message: 'Contact person removed from client Successfully.',
            type: 'success',
          }),
        );

        setState({ logging: false });
        setOpen(false);
        onLoad();
      })
      .catch((err: any) => {
        setState({ logging: false });
        setOpen(false);

        dispatch(
          onNotify({
            title: 'Error',
            message:
              err?.response?.data?.message ||
              err?.response?.data?.error ||
              err?.message ||
              err,
            type: 'danger',
          }),
        );
      });
  };

  const deleteIdRow = () => {
    switch (props.type) {
      case 'tag':
        return deleteTag();
      case 'status':
        return deleteStatus();
      case 'field':
        return deleteCustomField();
      case 'person':
        return deletePerson();
      case 'group':
        return deleteGroup();
      case 'client':
        return deleteClient();
      case 'todo list':
        return deleteTodoList();
      case 'todo item':
        return deleteTodoItem();
      case 'firm':
        return deleteFirm();
      case 'user':
        return deleteUser();
      case 'receipt':
        return deleteReceiptEntry();
      case 'comment':
        return deleteComment();
      case 'task':
        return deleteTask();
      case 'otherTask':
        return deleteOtherTask();
      case 'message template':
        return deleteMessageTemplate();
      case 'register in out':
        return deleteRegisterEntry();
      case 'keptAt':
        return deleteKeptAt();
      case 'document':
        return deleteDocument();
      case 'time tracking':
        return deleteTracking();
      case 'tag from client':
        return deleteTagFromClient();
      case 'group from client':
        return deleteGroupFromClient();
      case 'contact-person from client':
        return deletePersonFromClient();

      default:
        return;
    }
  };

  const setOpen = (open: boolean) => {
    props.deleteModalSetOpen?.(open);
  };

  const onLoad = () => {
    (props as any).onLoad();
  };

  return (
    <>
      <Transition.Root show={props.state.showDeleteModal} as={Fragment}>
        <Dialog as='div' className='relative z-40' onClose={() => null}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <div className='fixed z-10 inset-0 overflow-y-auto'>
            <div className='flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6'>
                  <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
                    <button
                      type='button'
                      className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      onClick={() => setOpen(false)}
                    >
                      <span className='sr-only'>Close</span>
                      <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                    </button>
                  </div>
                  <div className='sm:flex sm:items-start'>
                    <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
                      <ExclamationTriangleIcon
                        className='h-6 w-6 text-red-600'
                        aria-hidden='true'
                      />
                    </div>
                    <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                      <Dialog.Title
                        as='h3'
                        className='text-lg leading-6 font-medium text-gray-900'
                      >
                        Confirm Delete
                      </Dialog.Title>
                      <div className='mt-2'>
                        {props.type === 'comment' ? (
                          <p>Are you sure you want to delete this comment</p>
                        ) : props.type === 'task' ? (
                          <p>Are you sure you want to delete this task</p>
                        ) : props.type === 'time tracking' ? (
                          <p>
                            Are you sure you want to Cancel the Time Tracking?
                            The tracked time will be lost.
                          </p>
                        ) : (
                          <p className='text-sm text-gray-500'>
                            Are you sure you want to delete {props.type} '
                            {props.state.selectedRow?.name}'?
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='mt-5 sm:mt-4 sm:flex sm:justify-end'>
                    <button
                      type='button'
                      className='w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2  text-base bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:w-32 sm:text-sm'
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type='button'
                      // ref={myRef}
                      disabled={state.logging}
                      className={
                        'mt-3 sm:ml-4 w-full inline-flex items-center justify-center rounded-md border border-transparent shadow-sm py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:mt-0 sm:w-32 sm:text-sm'
                      }
                      onClick={deleteIdRow}
                    >
                      <span className='w-full flex justify-end'>
                        {state.logging ? (
                          <Icon name='loading' className='mr-2' />
                        ) : null}
                      </span>
                      <span>Delete</span>
                      <span className='w-full'></span>
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
