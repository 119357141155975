import { XMarkIcon } from '@heroicons/react/24/outline';

interface Props {
  message: string;
  fullWidth?: boolean;
  color?: 'red' | 'yellow';
  onClick?: () => void;
  closeIcon?: boolean;
  onClose?: () => void;
}

export default function Message(props: Props) {
  const dangerouslySetInnerHTML = {
    __html: props.message.replace(
      'Click here',
      `<span class="text-blue-600 cursor-pointer underline">Click here</span>`,
    ),
  };

  return (
    <div
      className={`border-l-4 ${
        props.color === 'red'
          ? 'bg-red-100 border-red-500 text-black'
          : 'bg-yellow-100 border-yellow-500 text-yellow-700'
      } ${
        props.fullWidth
          ? 'max-w-screen-2xl lg:mx-8'
          : 'max-w-7xl mx-auto lg:mx-8'
      } px-4 mb-4 flex gap-6 justify-between`}
      role='alert'
    >
      <p
        className={`font-bold grow py-2 ${
          props.onClick ? 'cursor-pointer' : ''
        }`}
        onClick={props.onClick}
        dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      ></p>
      {props.closeIcon && (
        <button onClick={props.onClose} className='text-xl'>
          <XMarkIcon className='h-6 w-6' />
        </button>
      )}
    </div>
  );
}
