import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { useId } from 'react';

type Props = {
  id?: string;
  className?: string;
  name: string;
  value: string;
  label?: string;
  error?: boolean;
  helpertext?: string;
  placeholder?: string;
  readOnly?: boolean;
  disabled?: boolean;
  rows?: number;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  onKeyDown?: (e: any) => void;
  onKeyPress?: (e: any) => void;
};

export default function TextAreaInput(props: Props) {
  const {
    id,
    className,
    name,
    onBlur,
    onChange,
    value,
    label,
    error,
    helpertext,
    placeholder,
    readOnly,
    disabled,
    onKeyDown,
    onKeyPress,
    rows,
  } = props;
  const inputId = useId();

  return (
    <div className={className}>
      {label ? (
        <label
          htmlFor={inputId}
          className='block text-sm font-medium text-gray-700'
        >
          {label}
        </label>
      ) : (
        <></>
      )}
      <div className='mt-1 relative rounded-md shadow-sm'>
        <textarea
          id={label ? inputId : id}
          name={name}
          onBlur={onBlur}
          value={value}
          onChange={onChange}
          onKeyPress={onKeyPress}
          placeholder={placeholder}
          readOnly={readOnly}
          disabled={disabled}
          onKeyDown={onKeyDown}
          rows={rows}
          className={
            error
              ? 'block w-full px-3 py-2 pr-10 border border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:border-red-500 sm:text-sm rounded-md'
              : 'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-500 focus:outline-none focus:border-indigo-500 sm:text-sm'
          }
        />
        {error && (
          <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
            <ExclamationCircleIcon
              className='h-5 w-5 text-red-500'
              aria-hidden='true'
            />
          </div>
        )}
      </div>
      {error ? (
        <p className='mt-2 text-sm text-red-600' id='email-error'>
          {helpertext}
        </p>
      ) : null}
    </div>
  );
}
