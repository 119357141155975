import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { useId } from 'react';
import ToolTip from './ToolTip';

type Props = {
  id?: string;
  name: string;
  value: string;
  tooltip?: string;
  label?: any;
  error?: boolean;
  helpertext?: string;
  type?: string;
  placeholder?: string;
  autoComplete?: string;
  disabled?: boolean;
  readOnly?: boolean;
  min?: string;
  max?: string;
  autoFocus?: boolean;
  required?: boolean;
  step?: string;
  pattern?: string;
  inputRef?: any;
  className?: string;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  onKeyDown?: (e: any) => void;
  onKeyPress?: (e: any) => void;
  onClick?: (e: any) => void;
  onFocus?: (e: any) => void;
  onMouseDown?: (e: any) => void;
};

export default function TextInput(props: Props) {
  const {
    id,
    name,
    value,
    tooltip,
    label,
    error,
    helpertext,
    type,
    placeholder,
    autoComplete,
    disabled,
    readOnly,
    min,
    max,
    autoFocus,
    required,
    step,
    pattern,
    inputRef,
    className,
    onChange,
    onBlur,
    onKeyDown,
    onKeyPress,
    onClick,
    onFocus,
    onMouseDown,
  } = props;

  const inputId = useId();

  return (
    <div className={className}>
      {label ? (
        <label
          htmlFor={inputId}
          className='block text-sm font-medium text-gray-700'
        >
          {label} {required ? <span className='text-red-500'>*</span> : <></>}{' '}
          {tooltip && tooltip !== '' ? <ToolTip content={tooltip} /> : null}
        </label>
      ) : (
        <></>
      )}

      <div className='mt-1 relative rounded-md shadow-sm'>
        <input
          id={label ? inputId : id}
          name={name}
          value={value}
          type={type || 'text'}
          placeholder={placeholder}
          autoComplete={autoComplete ? autoComplete : 'off'}
          disabled={disabled}
          readOnly={readOnly}
          min={min}
          max={max}
          autoFocus={autoFocus}
          required={required}
          step={step}
          pattern={pattern}
          ref={inputRef}
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onKeyPress={onKeyPress}
          onClick={onClick}
          onFocus={onFocus}
          onMouseDown={onMouseDown}
          className={
            error
              ? 'block w-full px-3 py-2 pr-10 border border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:border-red-500 sm:text-sm rounded-md'
              : 'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 sm:text-sm'
          }
        />
        {error && (
          <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
            <ExclamationCircleIcon
              className='h-5 w-5 text-red-500'
              aria-hidden='true'
            />
          </div>
        )}
      </div>
      {error ? (
        <p className='mt-2 text-sm text-red-600' id='email-error'>
          {helpertext}
        </p>
      ) : null}
    </div>
  );
}
