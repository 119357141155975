import { useEffect, useState } from 'react';
import Icon from './Icon';

export default function OfflineMessageBar() {
  const [offline, setOffline] = useState<boolean>(false);
  useEffect(() => {
    window.addEventListener('online', function () {
      setOffline(false);
    });

    window.addEventListener('offline', function () {
      setOffline(true);
    });

    return () => {
      window.removeEventListener('online', function () {
        setOffline(false);
      });

      window.removeEventListener('offline', function () {
        setOffline(true);
      });
    };
  }, []);

  if (!offline) return <></>;
  return (
    <>
      <div
        className={`fixed bottom-0 inset-x-0 z-10  inline-block w-full text-center text-white text-base font-bold transition-transform origin-bottom duration-300 ease-in-out py-3 bg-yellow-600`}
      >
        <div className='flex justify-center items-center'>
          <Icon name='warning' className='w-6 h-6' />
          <p>You are offline, Please connect to the internet!</p>
        </div>
      </div>
    </>
  );
}
