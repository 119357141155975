let config: {
  baseURL: string;
};

if (import.meta.env.VITE_APP_NODE_ENV === 'production') {
  config = {
    baseURL: 'https://pms.finexo.in/api',
  };
} else if (import.meta.env.VITE_APP_NODE_ENV === 'staging') {
  config = {
    baseURL: 'https://pmsdev.finexo.in/api',
  };
} else {
  config = {
    baseURL: 'http://localhost:5001/api',
  };
}

export default config;
