export default function clientNameFunction(clientDetails: any): string {
  const clientName =
    (clientDetails.clientFileNo ? `${clientDetails.clientFileNo} - ` : '') +
    (clientDetails.clientName ? `${clientDetails.clientName}` : '') +
    (clientDetails.clientTradeName
      ? ` - ${clientDetails.clientTradeName}`
      : '');

  return clientName;
}
