import { XMarkIcon } from '@heroicons/react/20/solid';
import { MutableRefObject, createRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import agent from '../agent';
import { RootState } from '../store/store';

interface IState {
  _id: string;
  text: string;
  startTime: Date;
  endTime: Date;
  buttonText: string;
  buttonLink: string;
  color: string;
  pages: string[];
  workspaces: string[];
  users: string[];
}

const COLORS = {
  red: 'bg-red-400',
  blue: 'bg-blue-400',
  green: 'bg-green-500',
  yellow: 'bg-yellow-600',
  gray: 'bg-slate-700',
};

export default function CustomMessageBar() {
  const [state, setState] = useState<IState | null>(null);
  const [canShow, setCanShow] = useState<boolean>(false);
  const [isClosed, setIsClosed] = useState<boolean>(false);
  const commonState = useSelector((state: RootState) => state.common);

  useEffect(() => {
    const closedAlerts = JSON.parse(
      localStorage.getItem('closed_alerts') || '[]',
    );
    if (state && closedAlerts.includes(state._id)) {
      setIsClosed(true);
    }
  }, [state]);

  const getAlertIntervalRef: MutableRefObject<NodeJS.Timeout | null> =
    createRef();

  useEffect(() => {
    getAlert(); // initial call

    getAlertIntervalRef.current = setInterval(
      () => {
        getAlert();
      },
      1000 * 60 * 15,
    );

    return () => {
      if (getAlertIntervalRef.current) {
        clearInterval(getAlertIntervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!state) return;

    const path = window.location.pathname;

    if (state.workspaces?.length > 0) {
      const isCurrentWk = state.workspaces?.some((ws) => path.includes(ws));

      if (isCurrentWk) {
        const isCurrentUser = state.users?.length
          ? state.users?.some((user) => user === commonState.currentUser?._id)
          : true;

        if (isCurrentUser) {
          const canShow = state.pages?.length
            ? state.pages?.some((page) => path.includes(page))
            : true;
          setCanShow(canShow);
        }
      } else {
        setCanShow(false);
      }
    } else {
      const canShow = state.pages?.length
        ? state.pages?.some((page) => path.includes(page))
        : true;
      setCanShow(canShow);
    }
  }, [state, window.location.pathname, commonState.currentUser]);

  function getAlert() {
    agent.FrontendVersion.getAlert().then((response) => {
      if (response.alertData) {
        setState(response.alertData);
      } else {
        setState(null);
      }
    });
  }

  function closeAlert() {
    const closedAlerts = JSON.parse(
      localStorage.getItem('closed_alerts') || '[]',
    );
    localStorage.setItem(
      'closed_alerts',
      JSON.stringify([...closedAlerts, state?._id]),
    );
    setState(null);
  }

  if (!state || !canShow || isClosed) return <></>;
  return (
    <>
      <div
        className={`fixed bottom-0 inset-x-0 z-10 inline-block w-full text-center text-white text-base font-bold transition-transform origin-bottom duration-300 ease-in-out py-3 ${
          COLORS[state.color] || 'bg-slate-700'
        }`}
      >
        <span>{state.text}</span>
        {state.buttonText && state.buttonLink && (
          <button
            className='ml-4 px-2 py-1 rounded-md bg-white text-slate-800 font-medium'
            onClick={() => {
              window.open(state.buttonLink, '_blank');
            }}
          >
            {state.buttonText}
          </button>
        )}
        <XMarkIcon
          onClick={closeAlert}
          className='h-6 w-6 inline ml-10 cursor-pointer'
        />
      </div>
    </>
  );
}
