import { createSlice } from '@reduxjs/toolkit';

const initialState: any = { gsts: [] };

const gstsSlice = createSlice({
  name: 'gsts',
  initialState,
  reducers: {
    getGsts: (state, action) => {
      state.gsts = action.payload.gsts;
    },
    addGst: (state, action) => {
      state.gsts.push(action.payload.gst);
      state.gsts.sort((a: any, b: any) => {
        const fa = a.lgnm.toLowerCase(),
          fb = b.lgnm.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
    },
  },
});

export const { getGsts, addGst } = gstsSlice.actions;

export default gstsSlice.reducer;
