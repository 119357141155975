export const colorsList = [
  { name: 'Blue', value: '#2563eb' },
  { name: 'Light Blue', value: '#60a5fa' },
  { name: 'Dark Blue', value: '#1e3a8a' },
  { name: 'Green', value: '#16a34a' },
  { name: 'Light Green', value: '#4ade80' },
  { name: 'Dark Green', value: '#14532d' },
  { name: 'Red', value: '#dc2626' },
  { name: 'Light Red', value: '#f87171' },
  { name: 'Dark Red', value: '#991b1b' },
  { name: 'Orange', value: '#f97316' },
  { name: 'Light Orange', value: '#fb923c' },
  { name: 'Dark Orange', value: '#c2410c' },
];
