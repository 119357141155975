import {
  Dialog,
  Disclosure,
  Menu,
  Popover,
  Transition,
} from '@headlessui/react';
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/20/solid';
import React, { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import agent from '../../agent';
import { adminRights } from '../../constants/defaultUserRights';
import {
  logout as onLogout,
  onNotify,
  updateCommon,
} from '../../store/reducers';
import { RootState } from '../../store/store';
import Icon from '../Icon';
import MultiSelect from '../MultiSelect';
import SearchNavigation from '../SearchNavigation';

import {
  ArrowDownTrayIcon,
  ArrowTopRightOnSquareIcon,
  BugAntIcon,
  ClockIcon,
  MapIcon,
} from '@heroicons/react/24/outline';
import { BLACKLISTED_ROUTES } from '../../constants/routes';
import { formatDate, formatDateAndTime } from '../../helpers/formatDate';
import { isMongoObjectId } from '../../helpers/misc.utils';
import { User, Workspace } from '../../helpers/types';
import { updateStatusInCommonState } from '../../helpers/updateStatusInCommonState';
import Maintenance from '../../pages/Maintenance';
import TimeTrackingModal from '../../pages/TimeTracking';
import CustomMessageBar from '../CustomMessageBar';
import ErrorBoundary from '../ErrorBoundry';
import Message from '../Message';
import MessageBar from '../MessageBar';
import OfflineMessageBar from '../OfflineMessageBar';
import ScrollToBottom from '../ScrollToTopBottom';

type LinkProps = {
  onlyIcon?: boolean;
  newLabel?: boolean;
  onClick?: () => void;
};

const BugReportLink = ({ onlyIcon }: LinkProps) => {
  return (
    <>
      {!onlyIcon && <hr className='my-4' />}
      <a
        href='https://forms.gle/kbuNZ5RmDSWcKmpH7'
        target='_blank'
        rel='noopener noreferrer'
        className={`text-white hover:bg-gray-700 hover:text-white group flex items-center text-sm font-medium rounded-md ${
          !onlyIcon && 'px-2 py-2 gap-x-3'
        }`}
      >
        <BugAntIcon className='text-gray-400 flex-shrink-0 h-5 w-5' />
        {!onlyIcon && (
          <>
            <span>Bug report / Contact Support</span>
            <ArrowTopRightOnSquareIcon className='text-gray-400 flex-shrink-0 h-4 w-4 ml-auto' />
          </>
        )}
      </a>
    </>
  );
};

const RoadMapLink = ({ onlyIcon }: LinkProps) => {
  return (
    <>
      <a
        href='https://produktly.com/roadmap/produktly/62a34643-7cc3-4f40-b6d9-75d302ea67ed'
        target='_blank'
        rel='noopener noreferrer'
        className={`text-white hover:bg-gray-700 hover:text-white group flex items-center text-sm font-medium rounded-md ${
          !onlyIcon && 'px-2 py-2 gap-x-3'
        }`}
      >
        <MapIcon className='text-gray-400 flex-shrink-0 h-5 w-5' />
        {!onlyIcon && (
          <>
            <span>Roadmap</span>
            <ArrowTopRightOnSquareIcon className='text-gray-400 flex-shrink-0 h-4 w-4 ml-auto' />
          </>
        )}
      </a>
    </>
  );
};

const ExtensionDownloadLink = ({ onlyIcon }: LinkProps) => {
  return (
    <a
      href='https://chromewebstore.google.com/detail/finexo-pms-login-extensio/bjhbhfainbagijicpomjiocdafmggcnh?hl=en'
      target='_blank'
      rel='noopener noreferrer'
      className={`text-white hover:bg-gray-700 hover:text-white group flex items-center text-sm font-medium rounded-md mb-10 ${
        !onlyIcon && 'px-2 py-2 gap-x-3'
      }`}
    >
      <ArrowDownTrayIcon className='text-gray-400 flex-shrink-0 h-5 w-5' />
      {!onlyIcon && (
        <>
          <span>Chrome Extension</span>
          <ArrowTopRightOnSquareIcon className='text-gray-400 flex-shrink-0 h-4 w-4 ml-auto' />
        </>
      )}
    </a>
  );
};

const ChangeLogLink = ({ onlyIcon, newLabel, onClick }: LinkProps) => {
  return (
    <>
      <div
        onClick={() => {
          openChangeLog();
          onClick && onClick();
        }}
        rel='noopener noreferrer'
        className={`text-white hover:bg-gray-700 cursor-pointer hover:text-white group flex items-center text-sm font-medium rounded-md ${
          !onlyIcon && 'px-2 py-2 gap-x-3'
        }`}
      >
        <Icon name={'outline/changelog'} aria-hidden='true' />
        {!onlyIcon && (
          <>
            <span>Change Log</span>
            {newLabel && (
              <span className='text-sm  border border-red-500 text-red-500 px-1 rounded-full'>
                New
              </span>
            )}
          </>
        )}
      </div>
    </>
  );
};

const openChangeLog = () => {
  interface ExtendedWindow extends Window {
    Produktly?: {
      openChangelog(options: { id: number }): void;
    };
  }

  const improvedWindow = window as ExtendedWindow;

  if (typeof improvedWindow.Produktly !== 'undefined') {
    improvedWindow.Produktly.openChangelog({ id: 186 });
  } else {
    // Handle the case where Produktly is not available
  }
};

export const getAllStatusList = (
  workSpaceId: string,
  updateCommon: any,
  onNotify: any,
) => {
  const searchText = '';
  const active = true;
  const limit = 100000;
  const skip = 0;
  if (workSpaceId) {
    agent.Status.getStatusList(workSpaceId, active, searchText, limit, skip)
      .then((response: any) => {
        const status = response.status;

        updateCommon(updateStatusInCommonState(status));
      })
      .catch((err: any) => {
        onNotify(
          'Could not fetch Status list',
          err?.response?.data?.message || err?.message || err,
          'danger',
        );
      });
  }
};

export const getAllTagsList = (
  workSpaceId: string,
  updateCommon: any,
  onNotify: any,
) => {
  const limit = 100000;
  const skip = 0;
  agent.Tag.getTagList(workSpaceId, true, '', limit, skip)
    .then((response: any) => {
      updateCommon({
        tags: response.tags.map(({ _id, name, description }: any) => ({
          _id,
          name,
          description,
        })),
      });
    })
    .catch((err: any) => {
      onNotify(
        'Could not load Tags List',
        err?.response?.data?.message || err?.message || err,
        'danger',
      );
    });
};

export const getAllUsersList = (
  workSpaceId: string,
  updateCommon: any,
  onNotify: any,
) => {
  const active = true;
  const includeCurrent = true;
  agent.User.getUserList(workSpaceId, active, '', includeCurrent)
    .then((response: any) => {
      updateCommon({
        users: response.users.map(
          ({ _id, name, shortname, email, mobileNumber, role }: any) => ({
            _id,
            name,
            email,
            mobileNumber,
            role,
            shortname,
          }),
        ),
      });
    })
    .catch((err: any) => {
      onNotify(
        'Could not load Users List',
        err?.response?.data?.message || err?.message || err,
        'danger',
      );
    });
};

export const getSelfDetails = (
  workSpaceId: string,
  updateCommon: any,
  onNotify: any,
) => {
  agent.User.getSelfDetails(workSpaceId)
    .then((response: any) => {
      updateCommon({
        currentUser: response.user,
      });
    })
    .catch((err: any) => {
      onNotify(
        'Could not load User Details',
        err?.response?.data?.message || err?.message || err,
        'danger',
      );
    });
};

export const getUserRights = (
  workSpaceId: string,
  updateCommon: any,
  onNotify: any,
) => {
  if (workSpaceId) {
    agent.User.getUserRights(workSpaceId)
      .then((response: any) => {
        if (
          Object.prototype.hasOwnProperty.call(response, 'allRights') &&
          response.allRights
        ) {
          updateCommon({ rights: adminRights });
        } else {
          updateCommon({ rights: response.rights });
        }
      })
      .catch((err: any) => {
        onNotify(
          'Could not fetch user rights',
          err?.response?.data?.message || err?.message || err,
          'danger',
        );
      });
  }
};

export const getCurrentlyTrackingTime = (
  workSpaceId: string,
  updateCommon: any,
  onNotify: any,
) => {
  agent.TimeTracking.currentlyWorking(workSpaceId)
    .then((res: any) => {
      updateCommon({
        timeTrackingRunning: res.allRecords[0]?.isRunning
          ? res.allRecords[0]?.isRunning
          : false,
      });
    })
    .catch((err: any) => {
      onNotify(
        'Could not fetch records',
        typeof err?.response?.data?.message === 'object'
          ? 'Something went wrong'
          : err?.response?.data?.message ||
              err?.response?.data?.error ||
              err?.message,
        'error',
      );
    });
};

const getAllUserTodoList = (
  workSpaceId: string,
  updateCommon: any,
  onNotify: any,
) => {
  agent.Todo.getAllTodoListOfUser(workSpaceId)
    .then((res: any) => {
      updateCommon({
        todoList: res.toDoList,
      });
    })
    .catch((err: any) => {
      onNotify(
        'Could not fetch todo list',
        typeof err?.response?.data?.message === 'object'
          ? 'Something went wrong'
          : err?.response?.data?.message ||
              err?.response?.data?.error ||
              err?.message,
        'error',
      );
    });
};

export const handleFirmChange = (
  item: any,
  onLoad: boolean,
  updateCommon: any,
  addNotification: any,
) => {
  return new Promise((resolve) => {
    updateCommon({ currentFirm: item });
    localStorage.setItem('currentFirm', JSON.stringify(item ?? '{}'));
    !onLoad &&
      addNotification('Firm Changed', `Current Firm ${item.name}`, 'success');
    resolve(true);
  });
};

export const checkIfPlanExpired = (currentFirm?: Workspace) => {
  const currentPlanExpired =
    currentFirm &&
    new Date(currentFirm.planExpiryDate).getTime() < new Date().getTime();

  return currentPlanExpired;
};

interface DashboardProps {
  children: ReactNode;
  currentFirm?: Workspace;
}

interface State {
  menuShow: boolean;
  loading: boolean;
  menuState: any;
  subMenuState: any;
  profileMenuShow: boolean;
  notifications: any;
  unreadNotifications: number;
  shrinkMenu: boolean;
  infoFooterHeight: number;
  showInfoFooter: boolean;
  planExpiringInFifteenDays: boolean;
  planExpiryMessage: string;
}

const initialState: State = {
  loading: false,
  menuShow: false,
  menuState: {},
  subMenuState: {},
  profileMenuShow: false,
  shrinkMenu: false,
  notifications: [],
  unreadNotifications: 0,
  infoFooterHeight: 0,
  showInfoFooter: true,
  planExpiringInFifteenDays: false,
  planExpiryMessage: '',
};

const Dashboard = (props: DashboardProps) => {
  const infoFooterRef = useRef<any>(null);
  const [state, setState] = useState(initialState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const commonState = useSelector((state: RootState) => state.common);
  const appConfig = useSelector((state: RootState) => state.appConfig);
  const [todoList, setTodoList] = useState([]);
  const [menuItemList, setMenuItemList] = useState([]);
  const [newChangeLog, setNewChangeLog] = useState(false);

  useEffect(() => {
    interface ClarityFunction {
      clarity?: { (method: string, ...args: any[]): void };
    }

    const improvedWindow = window as ClarityFunction;

    if (typeof improvedWindow.clarity !== 'undefined') {
      improvedWindow.clarity(
        'identify',
        commonState?.currentfirm,
        '',
        '',
        commonState?.currentUser?.email,
      );
    }
  }, [commonState.currentFirm]);

  useEffect(() => {
    setTodoList(commonState?.todoList);
  }, [commonState.todoList]);

  useEffect(() => {
    const updatedMenuItems = menuItems(commonState?.currentFirm?._id);

    updatedMenuItems.forEach((item: any) => {
      if (item.children) {
        item.children.forEach((child: any) => {
          if (location.pathname.includes(child.route)) {
            setState((prevState) => ({
              ...prevState,
              menuState: { [item.name]: true },
              subMenuState: { [child.name]: true },
            }));
          }
        });
      }
    });

    setMenuItemList(updatedMenuItems);
  }, [todoList]);

  const menuItems = (firmId: string) => {
    const list = todoList?.map((item) => {
      return {
        name: item.name || 'Default',
        iconName: 'outline/document-text',
        route: `/${firmId}/todo/${item._id}`,
      };
    });

    return [
      {
        name: 'Dashboard',
        iconName: 'outline/home',
        route: `/${firmId}/dashboard`,
      },
      {
        name: 'Tasks',
        iconName: 'outline/document-text',
        route: `/${firmId}/tasks/list`,
        children: [
          {
            name: 'Add',
            iconName: 'outline/document-add',
            route: `/${firmId}/tasks/add`,
          },
          {
            name: 'List',
            iconName: 'outline/document-text',
            route: `/${firmId}/tasks/list`,
          },
          {
            name: 'Attachments',
            iconName: 'outline/document-text',
            route: `/${firmId}/attachment/list`,
          },
        ],
      },
      {
        name: 'Clients',
        iconName: 'outline/users',
        route: `/${firmId}/clients/list`,
        children: [
          {
            name: 'Add',
            iconName: 'outline/user-plus',
            route: `/${firmId}/clients/add`,
          },
          {
            name: 'List',
            iconName: 'outline/users',
            route: `/${firmId}/clients/list`,
          },
          {
            name: 'Import',
            iconName: 'outline/user-plus',
            route: `/${firmId}/clients/import`,
          },
          // {
          //   name: 'Import by GSTIN',
          //   iconName: 'outline/user-plus',
          //   route: `/${firmId}/clients/import-by-gstin`,
          // },
          {
            name: 'Bulk Edit',
            iconName: 'outline/user-plus',
            route: `/${firmId}/clients/bulk-edit`,
          },
          {
            name: 'QRMP Preference',
            iconName: 'outline/clipboard-list',
            route: `/${firmId}/clients/qrmp`,
          },
          {
            name: 'Attachments',
            iconName: 'outline/document-text',
            route: `/${firmId}/clients/attachment/list`,
          },
        ],
      },
      {
        name: 'Contact Person',
        iconName: 'outline/user',
        route: `/${firmId}/contact-person/list`,
        children: [
          {
            name: 'List',
            iconName: 'outline/users',
            route: `/${firmId}/contact-person/list`,
          },
          {
            name: 'Import',
            iconName: 'outline/users',
            route: `/${firmId}/contact-person/import`,
          },
          {
            name: 'Bulk Edit',
            iconName: 'outline/users',
            route: `/${firmId}/contact-person/bulk-edit`,
          },

          {
            name: 'DSC Status',
            iconName: 'outline/document-text',
            route: `/${firmId}/contact-person/dsc-status`,
          },
        ],
      },
      {
        name: 'To Do',
        iconName: 'outline/document-text',
        route: `/${firmId}/todo/list/starred`,
        children: [
          {
            name: 'Starred',
            iconName: 'outline/star',
            route: `/${firmId}/todo/list/starred`,
          },
          {
            name: 'Today',
            iconName: 'outline/calendar',
            route: `/${firmId}/todo/list/today`,
          },
          {
            name: 'Overdue',
            iconName: 'outline/calendar',
            route: `/${firmId}/todo/list/overdue`,
          },
          {
            name: 'Week',
            iconName: 'outline/calendar',
            route: `/${firmId}/todo/list/next7Days`,
          },
          {
            name: 'Without due date',
            iconName: 'outline/calendar',
            route: `/${firmId}/todo/list/withoutDueDate`,
          },

          ...(list ? list : []), //only added list if it exists
        ],
      },
      {
        name: 'Receipts & Payments',
        iconName: 'outline/card',
        route: `/${firmId}/receipts-payments/list`,
        children: [
          {
            name: 'List',
            iconName: 'outline/card',
            route: `/${firmId}/receipts-payments/list`,
          },
          {
            name: 'Sources',
            iconName: 'outline/document-text',
            route: `/${firmId}/receipts-payments/source`,
          },
          {
            name: 'Categories',
            iconName: 'outline/document-text',
            route: `/${firmId}/receipts-payments/category`,
          },
        ],
      },
      {
        name: 'Register in out',
        iconName: 'outline/document-text',
        route: `/${firmId}/register-in-out/list`,
        children: [
          {
            name: 'List',
            iconName: 'outline/document-text',
            route: `/${firmId}/register-in-out/list`,
          },
          {
            name: 'Document',
            iconName: 'outline/document-text',
            route: `/${firmId}/register-in-out/document`,
          },
          {
            name: 'Kept At',
            iconName: 'outline/document-text',
            route: `/${firmId}/register-in-out/kept-at`,
          },
        ],
      },
      {
        name: 'Bulk Messages',
        iconName: 'outline/chat',
        route: `/${firmId}/bulk-messages`,
        children: [
          {
            name: 'Send',
            iconName: 'outline/chat',
            route: `/${firmId}/bulk-messages`,
          },
          {
            name: 'Templates',
            iconName: 'outline/document-text',
            route: `/${firmId}/bulk-message-template/list`,
          },
        ],
      },
      {
        name: 'Settings',
        iconName: 'outline/settings',
        route: `/${firmId}/custom-field/list`,
        children: [
          {
            name: 'Custom Field',
            iconName: 'outline/document-add',
            route: `/${firmId}/custom-field/list`,
          },
          {
            name: 'Groups',
            iconName: 'outline/group',
            route: `/${firmId}/groups/list`,
          },
          {
            name: 'Recurring Task',
            iconName: 'outline/document-text',
            route: `/${firmId}/recurring-task/list/gst`,
          },
          {
            name: 'Status',
            iconName: 'outline/document-text',
            route: `/${firmId}/status/list`,
          },
          {
            name: 'Tags',
            iconName: 'outline/tag',
            route: `/${firmId}/tags/list`,
          },
          {
            name: 'User',
            iconName: 'outline/user-plus',
            route: `/${firmId}/user/list`,
          },
          {
            name: 'Fees Tracking',
            iconName: 'outline/document-report',
            route: `/${firmId}/fees-tracking/list`,
          },
          // {
          //   name: 'Notification',
          //   iconName: 'outline/bell',
          //   route: `/${firmId}/notifications-settings`,
          // },
        ],
      },
      {
        name: 'Reports',
        iconName: 'outline/document-text',
        route: `/${firmId}/reports/list`,
      },
      // {
      //   name: 'Support',
      //   iconName: 'outline/support',
      //   route: `/${firmId}/support`,
      // },
    ];
  };

  const showHideInfoFooter = () => {
    setState((prevState) => ({
      ...prevState,
      showInfoFooter: !state.showInfoFooter,
    }));
  };

  const markAsRead = (id: any) => {
    setState((prevState) => ({ ...prevState, loading: true }));
    const currentFirm: Workspace = commonState.currentFirm;

    const workSpaceId = currentFirm._id as string;
    agent.Notifications.markAsRead(workSpaceId, id)
      .then((res: any) => {
        getNotificationsList();
        dispatch(
          onNotify({
            title: 'Notification marked as read successfully',
            message: res.message,
            type: 'success',
          }),
        );
      })
      .catch((err: any) => {
        setState((prevState) => ({ ...prevState, loading: false }));
        dispatch(
          onNotify({
            title: 'Could not mark notification as read',
            message:
              err?.response?.data?.message ||
              err?.response?.data?.error ||
              err?.message ||
              err,
            type: 'danger',
          }),
        );
      });
  };

  const getNotificationsList = () => {
    setState((prevState) => ({ ...prevState, loading: true }));
    const currentFirm: Workspace = commonState.currentFirm;

    const workSpaceId = currentFirm?._id;
    agent.Notifications.listInAppNotifications(workSpaceId, false, 0, 5)
      .then((res: any) => {
        setState((prevState) => ({
          ...prevState,
          notifications: res.notifications,
          unreadNotifications: res.count,
          loading: false,
        }));
      })
      .catch((err: any) => {
        setState((prevState) => ({ ...prevState, loading: false }));
        dispatch(
          onNotify({
            title: 'Could not fetch notifications',
            message:
              err?.response?.data?.message ||
              err?.response?.data?.error ||
              err?.message ||
              err,
            type: 'danger',
          }),
        );
      });
  };

  const onFirmChange = (firm: any, onload: boolean) =>
    handleFirmChange(
      firm,
      onload,
      (payload: any) => dispatch(updateCommon(payload)),
      (payload: { message: string; title: string; type: string }) =>
        dispatch(onNotify(payload)),
    );

  //Function to set the firm in user account on login as well as on firm change
  const setFirm = () => {
    const currentFirm: Workspace = commonState.currentFirm;

    const firmId = currentFirm?._id;
    const firms = commonState.firms;
    const localCurrentFirm = JSON.parse(
      localStorage.getItem('currentFirm') ?? '{}',
    );
    if (!firmId && firms?.length > 0) {
      const selectedLocalFirm = firms.find(
        (firm: any) => firm._id === localCurrentFirm._id,
      );
      if (selectedLocalFirm) {
        onFirmChange(selectedLocalFirm, true);
      } else {
        onFirmChange(firms[0], true);
      }
    } else if (firmId) {
      // This is for when the user is coming on any page other than firms page
      const selectFirm = firms?.find((firm: any) => firm._id === firmId);
      // selectFirm is undefined if the firmId is not present in the firms array
      if (selectFirm) {
        onFirmChange(selectFirm, true);
      }
    }
  };

  const menuListToggle = () => {
    const currentFirm: Workspace = commonState.currentFirm;
    const firmId = currentFirm?._id;

    if (firmId) {
      const childrenMenus: any = [];
      menuItems(firmId).forEach((item: any) => {
        if (item.children) {
          item.children.forEach((child: any) => {
            const subMenu = { name: item.name, route: child.route };
            childrenMenus.push(subMenu);
          });
        }
      });
      const path = location.pathname;
      const isSubMenu = childrenMenus.find((item: any) => item.route === path);
      if (isSubMenu) {
        setState((prevState) => ({
          ...prevState,
          menuState: { [isSubMenu.name]: true },
        }));
      }
    }
  };

  const toShrinkPage = () => {
    const pathname = location.pathname;
    const currentFirm: Workspace = commonState.currentFirm;

    const firmId = currentFirm?._id;

    if (
      pathname === `/${firmId}/tasks/list` ||
      pathname.includes('client-profile') ||
      pathname.includes('group-profile') ||
      (pathname.includes('/reports') && pathname.includes('-report')) ||
      pathname.includes('recurring-task/list') ||
      pathname.includes('subscription')
    ) {
      setState((prevState) => ({ ...prevState, shrinkMenu: true }));
    }
  };

  const fetchOnLoad = () => {
    const currentFirm: Workspace = commonState.currentFirm;

    const workSpaceId = currentFirm?._id;
    if (!workSpaceId) return;

    getUserRights(
      workSpaceId,
      (payload: any) => dispatch(updateCommon(payload)),
      (payload: { message: string; title: string; type: string }) =>
        dispatch(onNotify(payload)),
    );
    getAllStatusList(
      commonState?.currentFirm?._id,
      (payload: any) => dispatch(updateCommon(payload)),
      (payload: { message: string; title: string; type: string }) =>
        dispatch(onNotify(payload)),
    );
    getAllTagsList(
      commonState?.currentFirm?._id,
      (payload: any) => dispatch(updateCommon(payload)),
      (payload: { message: string; title: string; type: string }) =>
        dispatch(onNotify(payload)),
    );
    getAllUsersList(
      commonState?.currentFirm?._id,
      (payload: any) => dispatch(updateCommon(payload)),
      (payload: { message: string; title: string; type: string }) =>
        dispatch(onNotify(payload)),
    );
    getSelfDetails(
      commonState?.currentFirm?._id,
      (payload: any) => dispatch(updateCommon(payload)),
      (payload: { message: string; title: string; type: string }) =>
        dispatch(onNotify(payload)),
    );
    getCurrentlyTrackingTime(
      commonState?.currentFirm?._id,
      (payload: any) => dispatch(updateCommon(payload)),
      (payload: { message: string; title: string; type: string }) =>
        dispatch(onNotify(payload)),
    );
    getAllUserTodoList(
      commonState?.currentFirm?._id,
      (payload: any) => dispatch(updateCommon(payload)),
      (payload: { message: string; title: string; type: string }) =>
        dispatch(onNotify(payload)),
    );
    getNotificationsList();
  };

  const getUnreadChangeLog = () => {
    const produktlyUserId: string = localStorage.getItem('produktly_user_id');

    if (!produktlyUserId) return;

    agent.ChangeLog.getUnreadChangeLog(produktlyUserId)
      .then((response) => {
        if (response.data?.length > 0) {
          setNewChangeLog(true);
        } else {
          setNewChangeLog(false);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const currentFirm: Workspace = commonState.currentFirm;

    const firmId = currentFirm?._id;

    if (props.currentFirm?._id && checkIfPlanExpired(props.currentFirm)) {
      setPlanExpiryMessage();
    }
    updateRoute();
    toShrinkPage();
    menuListToggle();
    getUnreadChangeLog();

    firmId && firmId.length === 24 && getNotificationsList();

    setState((prevState) => ({
      ...prevState,
      infoFooterHeight: infoFooterRef.current
        ? infoFooterRef.current?.clientHeight
        : 0,
    }));
  }, []);

  useEffect(() => {
    if (commonState?.currentFirm?._id) {
      fetchOnLoad();
      updatePathName();
      setPlanExpiryMessage();
    }
  }, [commonState?.currentFirm?._id]);

  useEffect(() => {
    const currentFirm: Workspace = commonState.currentFirm;

    const currFirmId = currentFirm?._id;
    if (commonState?.firms || currFirmId?.length === 24) {
      setFirm();
    }
  }, [commonState?.firms]);

  useEffect(() => {
    const currPathname = location.pathname;
    if (currPathname) {
      menuListToggle();
      setState((prevState) => ({ ...prevState, menuShow: false }));
    }
  }, [location.pathname]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      infoFooterHeight: state.showInfoFooter
        ? infoFooterRef.current?.clientHeight || 0
        : 0,
    }));
  }, [state.showInfoFooter]);

  const setPlanExpiryMessage = () => {
    const currentFirm: Workspace = commonState.currentFirm;
    const currentUser: User = commonState.currentUser;

    let planExpiringInFifteenDays = false;
    let planExpiringInThreeDays = false;
    let planExpiryDate;
    let planExpiryMessage = '';

    if (currentFirm?.planExpiryDate) {
      planExpiringInFifteenDays =
        new Date(currentFirm.planExpiryDate).getTime() <
          new Date().getTime() + 15 * 24 * 60 * 60 * 1000 &&
        new Date(currentFirm.planExpiryDate).getTime() >= new Date().getTime();

      planExpiringInThreeDays =
        new Date(currentFirm.planExpiryDate).getTime() <
          new Date().getTime() + 3 * 24 * 60 * 60 * 1000 &&
        new Date(currentFirm.planExpiryDate).getTime() >= new Date().getTime();

      planExpiryDate = new Date(currentFirm.planExpiryDate);
    }

    if (checkIfPlanExpired(currentFirm)) {
      planExpiryMessage = `Your subscription has already expired on ${formatDate(
        planExpiryDate,
        false,
      )}. Click here to renew.`;
    } else if (
      currentFirm.isOwner ||
      (currentUser?.role &&
        (currentUser.role === 'Super Admin' || currentUser.role === 'admin'))
        ? planExpiringInFifteenDays
        : planExpiringInThreeDays
    ) {
      planExpiryMessage = `Your subscription is going to expire on ${formatDate(
        planExpiryDate,
        false,
      )}. Click here to renew.`;
    }

    setState((prevState) => ({ ...prevState, planExpiryMessage }));
  };

  const sendExpiryNotification = () => {
    dispatch(
      onNotify({
        title: 'Plan Expired',
        message:
          'Your plan has expired. Please renew or Upgrade your plan to continue using the application.',
        type: 'danger',
      }),
    );
  };

  const moveToSubscriptionPage = () => {
    const currentFirm: Workspace = commonState.currentFirm;

    if (checkIfPlanExpired(currentFirm)) {
      sendExpiryNotification();
    }
    if (window.location.href.includes('/paymentresponse')) return;
    navigate(`/${currentFirm._id}/subscriptions`, {
      replace: true,
    });
  };

  const updatePathName = () => {
    const currentFirm: Workspace = commonState.currentFirm;

    if (checkIfPlanExpired(currentFirm)) {
      return moveToSubscriptionPage();
    }

    const currentFirmId = currentFirm._id;
    const path = location.pathname;
    const searchParams = new URLSearchParams(location.search);

    dispatch(updateCommon({ urlInfo: '/' + currentFirmId }));

    let newPath;
    if (
      path === '/firms' ||
      path === '/paymentresponse' ||
      path === '/sign-up-completed'
    ) {
      newPath = path;
    } else if (path === '/' || path === '/dashboard' || path === '/signup') {
      newPath = `/${currentFirmId}/dashboard`;
    } else if (currentFirmId) {
      if (!path.includes(currentFirmId)) {
        const temp = path.split('/');
        temp[1] = currentFirmId;
        newPath = temp.join('/');
      } else {
        const currentPath = path
          .split('/')
          .filter((str) => str || isMongoObjectId(str))
          .join('/');

        newPath = `/${currentPath || 'dashboard'}`;
      }
    } else {
      newPath = path.includes(currentFirmId)
        ? path
        : '/' + currentFirmId + path;
    }

    if (searchParams) {
      navigate(`${newPath}?${searchParams.toString()}`);
    } else {
      navigate(newPath);
    }
  };

  const updateRoute = () => {
    const url = location.pathname;
    const currentFirm: Workspace = commonState.currentFirm;

    const paramLength = (currentFirm?._id as string)?.length;
    const path = url.substring(paramLength + 1);
    const selectedFirmId = commonState.currentFirm?._id;

    const menuState: any = {};
    const subMenuState: any = {};
    menuItems(selectedFirmId).forEach((menuItem: any) => {
      if (path.indexOf(menuItem.route) === 0) {
        menuState[menuItem.name] = true;
      }
      menuItem.children?.forEach((subItem: any) => {
        if (path.indexOf(subItem.route) === 0) {
          subMenuState[subItem.name] = true;
        }
      });
    });
    setState((prevState) => ({ ...prevState, menuState, subMenuState }));
  };

  const toggleDropdown = (name: any) => {
    if (state.menuState[name]) {
      setState((prevState) => ({ ...prevState, menuState: {} }));
    } else {
      setState((prevState) => ({ ...prevState, menuState: { [name]: true } }));
    }
  };

  const toggleSubItemDropdown = (name: any) => {
    if (state.subMenuState[name]) {
      setState((prevState) => ({ ...prevState, subMenuState: {} }));
    } else {
      setState((prevState) => ({
        ...prevState,
        subMenuState: { [name]: true },
      }));
    }
  };

  const openModalHandler = (modalName: string) => {
    dispatch(
      updateCommon({
        currentModal: { modalName, fetchAgain: false, type: 'add' },
      }),
    );
  };

  const openTimeTrackingModal = () => {
    dispatch(
      updateCommon({
        currentModal: { modalName: 'TIME_TRACKING_MODAL' },
      }),
    );
  };

  const closeTimeTrackingModal = () => {
    if (commonState.taskModalPath) navigate(commonState.taskModalPath);

    dispatch(
      updateCommon({
        currentModal: { modalName: '', fetchAgain: false },
        taskModalPath: null,
      }),
    );
  };

  const toggleMenu = () => {
    setState((prevState) => ({ ...prevState, menuShow: !state.menuShow }));
  };

  const toggleProfileMenu = () => {
    setState((prevState) => ({
      ...prevState,
      profileMenuShow: !state.profileMenuShow,
    }));
  };

  const noFirmClickHandler = (route: string) => {
    const currentFirm: Workspace = commonState.currentFirm;
    if (!state.loading) {
      if (linkRouteDirect(route) === route) return;
      if (linkRouteDirect(route) === '/firms') {
        setState((prevState) => ({ ...prevState, menuShow: false }));
        dispatch(
          updateCommon({
            currentModal: { modalName: 'ADD_FIRM_MODAL', fetchAgain: false },
          }),
        );
      }
      if (linkRouteDirect(route) === `/${currentFirm._id}/subscriptions`) {
        sendExpiryNotification();
      }
    }
  };

  const linkRouteDirect = (route: string) => {
    const currentFirm: Workspace = commonState.currentFirm;
    const firmsCount = commonState.firms ? commonState.firms?.length : 0;

    if (checkIfPlanExpired(currentFirm)) {
      return `/${currentFirm._id}/subscriptions`;
    } else if (firmsCount === 0) {
      return '/firms';
    } else {
      return route;
    }
  };

  const logout = () => {
    dispatch(onLogout());

    localStorage.removeItem('currentFirm');
    dispatch(
      updateCommon({
        firms: [],
        currentFirm: undefined,
        isFirmPresent: false,
        status: [],
        taskStatus: [],
      }),
    );
    dispatch(
      onNotify({
        title: 'Successful',
        message: 'You have been successfully logged out.',
        type: 'success',
      }),
    );
  };

  const onShrinkMenuHover = () => {
    const toShrink = shrinkedMenuPages();
    if (toShrink) {
      setState((prevState) => ({
        ...prevState,
        shrinkMenu: !state.shrinkMenu,
      }));
    }
  };

  const shrinkedMenuPages = () => {
    const pathname = location.pathname;
    const currentFirm: Workspace = commonState.currentFirm;

    const firmId = currentFirm?._id;
    return (
      pathname === `/${firmId}/tasks/list` ||
      pathname.includes('client-profile') ||
      pathname.includes('group-profile') ||
      (pathname.includes('/reports') && pathname.includes('-report')) ||
      pathname.includes('recurring-task/list') ||
      pathname.includes('subscription')
    );
  };

  useEffect(() => {
    const toShrink = shrinkedMenuPages();
    if (toShrink) {
      setState((prevState) => ({ ...prevState, shrinkMenu: true }));
    } else {
      setState((prevState) => ({ ...prevState, shrinkMenu: false }));
    }
  }, [location.pathname]);

  if (appConfig.showMaintenance) {
    return <Maintenance />;
  }

  if (
    BLACKLISTED_ROUTES.includes(location.pathname.substring(0, 18)) ||
    location.pathname.includes('?r=')
  ) {
    return (
      <>
        {props.children}
        <div ref={infoFooterRef} className={`fixed md:relative`}>
          <MessageBar
            showInfoFooter={state.showInfoFooter}
            showHideInfoFooter={showHideInfoFooter}
            infoFooterHeight={state.infoFooterHeight}
          />
        </div>
        <div className='fixed md:relative'>
          <CustomMessageBar />
        </div>
        <div className='fixed md:relative'>
          <OfflineMessageBar />
        </div>
      </>
    );
  }

  return (
    <div>
      <div
        style={
          {
            height: `calc(100vh - ${state.infoFooterHeight}px)`,
          } as React.CSSProperties
        }
        className='flex overflow-hidden bg-gray-100'
      >
        {/* <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. --> */}

        <Transition.Root show={state.menuShow} as={Fragment}>
          <Dialog
            as='div'
            className='relative z-40 md:hidden'
            onClose={toggleMenu}
          >
            <Transition.Child
              as={Fragment}
              enter='transition-opacity ease-linear duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity ease-linear duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-gray-600 bg-opacity-75' />
            </Transition.Child>

            <div className='fixed inset-0 flex z-40'>
              <Transition.Child
                as={Fragment}
                enter='transition ease-in-out duration-300 transform'
                enterFrom='-translate-x-full'
                enterTo='translate-x-0'
                leave='transition ease-in-out duration-300 transform'
                leaveFrom='translate-x-0'
                leaveTo='-translate-x-full'
              >
                <Dialog.Panel className='relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-in-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-300'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <div className='absolute top-0 right-0 -mr-12 pt-2'>
                      <button
                        type='button'
                        className='ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                        onClick={toggleMenu}
                      >
                        <span className='sr-only'>Close sidebar</span>
                        <XMarkIcon
                          className='h-6 w-6 text-white'
                          aria-hidden='true'
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className='flex-shrink-0 flex items-center px-4'>
                    <img
                      className='h-8 w-auto'
                      src='/images/Finexo-PMS.png'
                      alt='Finexo PMS'
                    />
                  </div>
                  <div className='mt-5 flex-1 max-h-[calc(100vh-7rem)] overflow-y-auto vertical-scroll'>
                    <nav className='px-2 space-y-1'>
                      <div className='sm:hidden mt-4 pb-4 mb-2 border-b border-white'>
                        {(commonState.currentFirm?._id ||
                          location.pathname === '/firms') &&
                          commonState.firms?.length > 1 && (
                            <div className='w-full px-3'>
                              <MultiSelect
                                items={commonState?.firms?.map((firm: any) => {
                                  return {
                                    ...firm,
                                    name: firm?.name,
                                  };
                                })}
                                selected={{
                                  name: commonState?.currentFirm?.name || '',
                                }}
                                type='firms'
                                onChange={(selected) =>
                                  onFirmChange(selected, false)
                                }
                                placeholder='Select Firm'
                              />
                            </div>
                          )}
                      </div>
                      {menuItemList.map((menuItem: any) =>
                        !menuItem.children ? (
                          <div key={menuItem.name}>
                            <Link
                              to={linkRouteDirect(menuItem.route)}
                              key={menuItem.name}
                              className={`group flex items-center px-2 py-2 text-sm font-medium rounded-md text-white ${
                                location.pathname === menuItem.route
                                  ? 'bg-gray-900'
                                  : 'text-gray-300 hover:text-white hover:bg-gray-700'
                              }`}
                              onClick={() => noFirmClickHandler(menuItem.route)}
                            >
                              <Icon
                                name={menuItem.iconName}
                                className={
                                  state.menuState[menuItem.name]
                                    ? 'text-gray-500 mr-3 flex-shrink-0 h-5 w-5'
                                    : 'text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-5 w-5'
                                }
                                aria-hidden='true'
                              />
                              {menuItem.name}
                            </Link>
                          </div>
                        ) : (
                          <div key={menuItem.name}>
                            <Disclosure
                              as='div'
                              key={menuItem.name}
                              className='space-y-1'
                            >
                              {() => (
                                <div
                                  onClick={() => toggleDropdown(menuItem.name)}
                                >
                                  <Disclosure.Button
                                    as={Link}
                                    to={linkRouteDirect(
                                      menuItem.route ? menuItem.route : '',
                                    )}
                                    onClick={() =>
                                      noFirmClickHandler(menuItem.route)
                                    }
                                    className='text-gray-300 w-full justify-between hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                                  >
                                    <span className='flex items-center'>
                                      <Icon
                                        name={menuItem.iconName}
                                        className='mr-3 flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                                        aria-hidden='true'
                                      />
                                      {menuItem.name}
                                    </span>
                                    <ChevronDownIcon
                                      className={
                                        !state.menuState[menuItem.name]
                                          ? 'text-gray-400 -rotate-90 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150'
                                          : 'text-gray-300 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150'
                                      }
                                    />
                                  </Disclosure.Button>
                                  {state.menuState[menuItem.name] ? (
                                    <Disclosure.Panel
                                      className='space-y-1 ml-11 border-l border-gray-700'
                                      static
                                    >
                                      {menuItem.children.map((subItem: any) =>
                                        !subItem.children ? (
                                          <div key={subItem.name}>
                                            <Link
                                              to={linkRouteDirect(
                                                subItem.route,
                                              )}
                                              key={subItem.name}
                                              className={
                                                location.pathname ===
                                                subItem.route
                                                  ? 'bg-gray-900 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                                                  : 'text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                                              }
                                              onClick={() =>
                                                noFirmClickHandler(
                                                  menuItem.route,
                                                )
                                              }
                                            >
                                              <Icon
                                                name={subItem.iconName}
                                                className={
                                                  state.menuState[menuItem.name]
                                                    ? 'text-gray-500 mr-3 flex-shrink-0 h-5 w-5'
                                                    : 'text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-5 w-5'
                                                }
                                              />
                                              {subItem.name}
                                            </Link>
                                          </div>
                                        ) : (
                                          <div key={subItem.name}>
                                            <Disclosure
                                              as='div'
                                              key={subItem.name}
                                              className='space-y-1'
                                            >
                                              {() => (
                                                <div
                                                  onClick={() =>
                                                    toggleSubItemDropdown(
                                                      subItem.name,
                                                    )
                                                  }
                                                >
                                                  <Disclosure.Button className='text-gray-300 w-full justify-between hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md'>
                                                    <span className='flex items-center'>
                                                      {subItem.name}
                                                    </span>
                                                    <ChevronDownIcon
                                                      className={
                                                        !state.subMenuState[
                                                          subItem.name
                                                        ]
                                                          ? 'text-gray-400 -rotate-90 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150'
                                                          : 'text-gray-300 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150'
                                                      }
                                                    />
                                                  </Disclosure.Button>
                                                  {state.subMenuState[
                                                    subItem.name
                                                  ] ? (
                                                    <Disclosure.Panel
                                                      className='space-y-1 ml-6 border-l border-gray-700'
                                                      static
                                                    >
                                                      {subItem.children.map(
                                                        (subSubItem: any) => (
                                                          <Link
                                                            to={linkRouteDirect(
                                                              subSubItem.route,
                                                            )}
                                                            key={
                                                              subSubItem.name
                                                            }
                                                            className={
                                                              location.pathname ===
                                                              subSubItem.route
                                                                ? 'bg-gray-900 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                                                                : 'text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                                                            }
                                                            onClick={() =>
                                                              noFirmClickHandler(
                                                                menuItem.route,
                                                              )
                                                            }
                                                          >
                                                            {subSubItem.name}
                                                          </Link>
                                                        ),
                                                      )}
                                                    </Disclosure.Panel>
                                                  ) : null}
                                                </div>
                                              )}
                                            </Disclosure>
                                          </div>
                                        ),
                                      )}
                                    </Disclosure.Panel>
                                  ) : null}
                                </div>
                              )}
                            </Disclosure>
                          </div>
                        ),
                      )}
                      <BugReportLink />
                      <ChangeLogLink
                        newLabel={newChangeLog}
                        onClick={getUnreadChangeLog}
                      />
                      <RoadMapLink />
                      <ExtensionDownloadLink />
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className='flex-shrink-0 w-14' aria-hidden='true'>
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* <!-- Static sidebar for desktop --> */}
        <>
          {state.shrinkMenu && (
            <div
              className='hidden md:flex md:flex-col md:gap-5 p-4 bg-gray-800 relative z-40'
              onMouseEnter={onShrinkMenuHover}
            >
              <div className='h-12 mb-1'>
                <Icon name='point-right' className='h-6 w-6 text-gray-400' />
              </div>
              {menuItemList.map((item, index) => (
                <div key={index + item.iconName}>
                  <Icon
                    name={item.iconName}
                    className={
                      'text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-5 w-5'
                    }
                    aria-hidden='true'
                  />
                </div>
              ))}
              <BugReportLink onlyIcon={state.shrinkMenu} />
              <ChangeLogLink
                onlyIcon={state.shrinkMenu}
                newLabel={newChangeLog}
                onClick={getUnreadChangeLog}
              />
              <RoadMapLink onlyIcon={state.shrinkMenu} />
              <ExtensionDownloadLink onlyIcon={state.shrinkMenu} />
            </div>
          )}
          {!state.shrinkMenu && (
            <div
              className={`${
                state.shrinkMenu
                  ? 'hidden md:flex md:flex-shrink-0 fixed inset-0 h-full w-fit z-50'
                  : 'hidden md:flex md:flex-shrink-0'
              }`}
              onMouseLeave={onShrinkMenuHover}
            >
              <div className='flex flex-col w-64 bg-gray-800'>
                {/* <!-- Sidebar component, swap this element with another sidebar if you like --> */}
                <div className='grid grid-rows-[4rem,auto,3rem] min-h-screen relative'>
                  <div className='flex items-center h-16 flex-shrink-0 px-4 bg-white'>
                    <img
                      className='h-8 m-auto w-auto'
                      src='/images/Finexo-PMS.png'
                      alt='Finexo Logo'
                    />
                  </div>
                  <div className='flex-1 flex flex-col overflow-y-auto vertical-scroll'>
                    <nav className='flex-1 px-2 py-4 space-y-1'>
                      {menuItemList.map((menuItem: any) =>
                        !menuItem.children ? (
                          <div key={menuItem.name}>
                            <Link
                              to={linkRouteDirect(menuItem.route)}
                              key={menuItem.name}
                              className={`group flex items-center px-2 py-2 text-sm font-medium rounded-md text-white ${
                                location.pathname === menuItem.route
                                  ? 'bg-gray-900'
                                  : 'text-gray-300 hover:text-white hover:bg-gray-700'
                              }`}
                              onClick={() => noFirmClickHandler(menuItem.route)}
                            >
                              <Icon
                                name={menuItem.iconName}
                                className={
                                  state.menuState[menuItem.name]
                                    ? 'text-gray-500 mr-3 flex-shrink-0 h-5 w-5'
                                    : 'text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-5 w-5'
                                }
                                aria-hidden='true'
                              />
                              {menuItem.name}
                            </Link>
                          </div>
                        ) : (
                          <div key={menuItem.name}>
                            <Disclosure
                              as='div'
                              key={menuItem.name}
                              className='space-y-1'
                            >
                              {() => (
                                <div>
                                  <div
                                    onClick={() =>
                                      toggleDropdown(menuItem.name)
                                    }
                                  >
                                    <Disclosure.Button
                                      onClick={() =>
                                        noFirmClickHandler(menuItem.route)
                                      }
                                      className='text-gray-300 w-full justify-between hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                                    >
                                      <span className='flex items-center'>
                                        <Icon
                                          name={menuItem.iconName}
                                          className='mr-3 flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                                          aria-hidden='true'
                                        />
                                        {menuItem.name}
                                      </span>
                                      <ChevronDownIcon
                                        className={
                                          !state.menuState[menuItem.name]
                                            ? 'text-gray-400 -rotate-90 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150'
                                            : 'text-gray-300 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150'
                                        }
                                      />
                                    </Disclosure.Button>
                                  </div>
                                  {state.menuState[menuItem.name] ? (
                                    <Disclosure.Panel
                                      className='space-y-1 ml-11 border-l border-gray-700'
                                      static
                                    >
                                      {menuItem.children.map((subItem: any) =>
                                        !subItem.children ? (
                                          <div key={subItem.name}>
                                            <Link
                                              to={linkRouteDirect(
                                                subItem.route,
                                              )}
                                              key={subItem.name}
                                              className={
                                                location.pathname ===
                                                subItem.route
                                                  ? 'bg-gray-900 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                                                  : 'text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                                              }
                                              onClick={() =>
                                                noFirmClickHandler(
                                                  menuItem.route,
                                                )
                                              }
                                            >
                                              <Icon
                                                name={subItem.iconName}
                                                className={
                                                  state.menuState[menuItem.name]
                                                    ? 'text-gray-500 mr-3 flex-shrink-0 h-5 w-5'
                                                    : 'text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-5 w-5'
                                                }
                                              />
                                              {subItem.name}
                                            </Link>
                                          </div>
                                        ) : (
                                          <div key={subItem.name}>
                                            <Disclosure
                                              as='div'
                                              key={subItem.name}
                                              className='space-y-1'
                                            >
                                              {() => (
                                                <div
                                                  onClick={() =>
                                                    toggleSubItemDropdown(
                                                      subItem.name,
                                                    )
                                                  }
                                                >
                                                  <Disclosure.Button className='text-gray-300 w-full justify-between hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md'>
                                                    <span className='flex items-center'>
                                                      <Icon
                                                        name={subItem.iconName}
                                                        className='mr-3 flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                                                        aria-hidden='true'
                                                      />
                                                      {subItem.name}
                                                    </span>
                                                    <ChevronDownIcon
                                                      className={
                                                        !state.subMenuState[
                                                          subItem.name
                                                        ]
                                                          ? 'text-gray-400 -rotate-90 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150'
                                                          : 'text-gray-300 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150'
                                                      }
                                                    />
                                                  </Disclosure.Button>
                                                  {state.subMenuState[
                                                    subItem.name
                                                  ] ? (
                                                    <Disclosure.Panel
                                                      className='space-y-1 ml-6 border-l border-gray-700'
                                                      static
                                                    >
                                                      {subItem.children.map(
                                                        (subSubItem: any) => (
                                                          <Link
                                                            to={linkRouteDirect(
                                                              subSubItem.route,
                                                            )}
                                                            key={
                                                              subSubItem.name
                                                            }
                                                            className={
                                                              location.pathname ===
                                                              subSubItem.route
                                                                ? 'bg-gray-900 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                                                                : 'text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                                                            }
                                                            onClick={() =>
                                                              noFirmClickHandler(
                                                                menuItem.route,
                                                              )
                                                            }
                                                          >
                                                            {subSubItem.name}
                                                          </Link>
                                                        ),
                                                      )}
                                                    </Disclosure.Panel>
                                                  ) : null}
                                                </div>
                                              )}
                                            </Disclosure>
                                          </div>
                                        ),
                                      )}
                                    </Disclosure.Panel>
                                  ) : null}
                                </div>
                              )}
                            </Disclosure>
                          </div>
                        ),
                      )}
                      <BugReportLink onlyIcon={state.shrinkMenu} />
                      <ChangeLogLink
                        onlyIcon={state.shrinkMenu}
                        newLabel={newChangeLog}
                        onClick={getUnreadChangeLog}
                      />
                      <RoadMapLink onlyIcon={state.shrinkMenu} />
                      <ExtensionDownloadLink onlyIcon={state.shrinkMenu} />
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>

        <div className='flex flex-col w-0 flex-1 overflow-hidden'>
          <div className='relative z-10 flex-shrink-0 flex h-16 bg-white shadow'>
            <button
              className='px-4 border-r border-gray-200 text-gray-500 focus:outline-none md:hidden'
              onClick={toggleMenu}
            >
              <span className='sr-only'>Open sidebar</span>
              <Icon
                name='outline/menu-alt-2'
                className='h-6 w-6'
                onClick={toggleMenu}
              />
            </button>
            <div className='flex-1 px-4 flex justify-between'>
              <div className='w-full flex items-center justify-between gap-4'>
                <div className='flex items-center gap-4'>
                  {commonState?.firms?.length > 1 && (
                    <div className='hidden sm:block ml-4 w-48 lg:w-64 grow'>
                      <MultiSelect
                        items={commonState?.firms?.map((firm: any) => ({
                          ...firm,
                          name: firm?.name,
                        }))}
                        selected={{
                          name: commonState?.currentFirm?.name || '',
                        }}
                        disabled={commonState?.firms?.length === 1}
                        type='firms'
                        onChange={(selected) => onFirmChange(selected, false)}
                        placeholder='Select Firm'
                      />
                    </div>
                  )}
                  {/* )} */}
                  <SearchNavigation openModalHandler={openModalHandler} />
                  <button
                    type='button'
                    className='relative'
                    onClick={openTimeTrackingModal}
                  >
                    <ClockIcon
                      className='h-7 w-7 text-gray-700'
                      aria-hidden='true'
                    />
                    {commonState.timeTrackingRunning && (
                      <div className='w-3 h-3 bg-green-500 rounded-full animate-ping absolute top-0 right-0'></div>
                    )}
                    <div
                      className={`w-3 h-3 ${
                        commonState.timeTrackingRunning
                          ? 'bg-green-500'
                          : 'bg-red-500'
                      } rounded-full absolute top-0 right-0`}
                    ></div>
                  </button>
                </div>
                <div className='flex items-center gap-4 md:gap-8'>
                  <Popover className=''>
                    <Popover.Button>
                      <span className='sr-only'>View notifications</span>
                      <Icon
                        name='outline/bell'
                        className='h-6 w-6 stroke-gray-500'
                      />
                      {state.unreadNotifications > 0 && (
                        <>
                          <div className='w-3 h-3 bg-green-500 rounded-full animate-ping absolute top-0 right-0'></div>
                          <div className='w-3 h-3 bg-green-500 rounded-full absolute top-0 right-0'></div>
                        </>
                      )}
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter='transition ease-out duration-200'
                      enterFrom='opacity-0 translate-y-1'
                      enterTo='opacity-100 translate-y-0'
                      leave='transition ease-in duration-150'
                      leaveFrom='opacity-100 translate-y-0'
                      leaveTo='opacity-0 translate-y-1'
                    >
                      <Popover.Panel className='absolute left-1/2 transform -translate-x-1/2 sm:right-20 sm:left-auto sm:transform-none sm:translate-0  z-10 mt-6 min-w-[22rem] max-w-sm sm:px-0 bg-white rounded-lg'>
                        <div className='rounded-lg shadow-xl ring-1 ring-black ring-opacity-5'>
                          <div className='rounded-lg transition ease-in-out duration-150 px-3 py-3 grid gap-4 max-h-[80vh] vertical-scroll overflow-auto'>
                            <div className='divide-y-4 divide-white'>
                              {state.unreadNotifications > 0 ? (
                                state.notifications.map((notification: any) => {
                                  return (
                                    <div
                                      key={notification._id}
                                      className={`text-sm space-y-2 py-3 px-3 hover:bg-gray-100 ${
                                        notification.read ? '' : 'bg-gray-50'
                                      }`}
                                    >
                                      <div className='space-y-1'>
                                        <p className='text-gray-900 font-medium'>
                                          {notification.title}
                                        </p>
                                        <p className='text-xs'>
                                          {notification.description}
                                        </p>
                                      </div>
                                      <div className='flex items-center justify-between'>
                                        <p className='text-xs text-gray-500'>
                                          {formatDateAndTime(
                                            notification.timestamp,
                                          )}
                                        </p>
                                        {!notification.read && (
                                          <button
                                            className='text-xs text-right place-self-end whitespace-nowrap px-2 py-1 focus-visible:outline-indigo-600 rounded border border-transparent hover:outline hover:outline-2 hover:outline-indigo-600'
                                            onClick={() => {
                                              markAsRead(notification._id);
                                            }}
                                          >
                                            Mark as Read
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className='text-center py-3 px-3'>
                                  <p className='text-sm text-gray-900 font-medium'>
                                    You are all caught up! No new notifications
                                    here.
                                  </p>
                                </div>
                              )}
                            </div>
                            <div className='mx-2 mb-2 flex'>
                              <Popover.Button
                                as={Link}
                                to={`/${commonState.currentFirm?._id}/notification/list`}
                                className='ml-auto px-4 inline-flex items-center justify-center rounded-md border border-transparent border-gray-300 shadow-sm py-2 bg-indigo-600 text-sm font-medium text-white hover:bg-indigo-700 disabled:bg-indigo-700 focus:outline-none focus:bg-indigo-700'
                              >
                                View All
                              </Popover.Button>
                            </div>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </Popover>

                  {/* <!-- Profile dropdown --> */}
                  <Menu as='div' className='inline-block relative'>
                    <Menu.Button>
                      <div
                        className='max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none'
                        id='user-menu-button'
                        aria-expanded='false'
                        aria-haspopup='true'
                        onClick={toggleProfileMenu}
                      >
                        <span className='sr-only'>Open user menu</span>
                        <span className='w-8 h-8 p-2 rounded-full cursor-pointer inline-flex items-center justify-center bg-gray-700 text-white mr-3 text-sm xs:text-base font-bold'>
                          {commonState?.currentUser?.shortname || 'Hi'}
                        </span>
                      </div>
                    </Menu.Button>

                    <Transition
                      as={Fragment}
                      enter='transition ease-out duration-100'
                      enterFrom='transform opacity-0 scale-95'
                      enterTo='transform opacity-100 scale-100'
                      leave='transition ease-in duration-75'
                      leaveFrom='transform opacity-100 scale-100'
                      leaveTo='transform opacity-0 scale-95'
                    >
                      <div>
                        <Menu.Items className='origin-top-right absolute right-0 mt-5 w-56 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
                          <div className='py-1'>
                            <Menu.Item>
                              <Link
                                to={`/${commonState.currentFirm?._id}/profile/general`}
                                className='flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
                                role='menuitem'
                                tabIndex={-1}
                                id='user-menu-item-0'
                              >
                                <Icon
                                  name='outline/user'
                                  className='h-5 w-5 mr-2 text-gray-700'
                                />
                                <span>Your Profile</span>
                              </Link>
                            </Menu.Item>
                            <Menu.Item>
                              <Link
                                to='/firms'
                                className='flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
                                role='menuitem'
                                tabIndex={-1}
                                id='user-menu-item-0'
                              >
                                <Icon
                                  name='building-office-2'
                                  className='h-5 w-5 mr-2 text-gray-700'
                                />
                                <span>Your Firms</span>
                              </Link>
                            </Menu.Item>
                            <Menu.Item>
                              <Link
                                to={linkRouteDirect(
                                  `/${commonState.currentFirm?._id}/subscriptions`,
                                )}
                                className='flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
                                role='menuitem'
                                tabIndex={-1}
                                id='user-menu-item-0'
                              >
                                <Icon
                                  name='outline/card'
                                  className='h-5 w-5 mr-2 text-gray-700'
                                />
                                <span>Subscription</span>
                              </Link>
                            </Menu.Item>
                            <Menu.Item>
                              <button
                                className='flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
                                role='menuitem'
                                tabIndex={-1}
                                id='user-menu-item-0'
                                onClick={logout}
                              >
                                <Icon
                                  name='outline/logout'
                                  className='h-5 w-5 mr-2 text-gray-700'
                                />
                                <span>Sign out</span>
                              </button>
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </div>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          <main className='flex-1 relative overflow-y-auto overflow-x-hidden focus:outline-none'>
            <div className='py-6'>
              <div
                className={`mx-auto ${
                  shrinkedMenuPages()
                    ? 'w-full max-w-[95rem] px-4 sm:px-6'
                    : 'max-w-7xl px-4 sm:px-2 md:px-4'
                }`}
              >
                <ScrollToBottom
                  id='dashboard-page'
                  bottomOffset={state.infoFooterHeight}
                >
                  <ErrorBoundary type='dashboard'>
                    {state.planExpiryMessage && (
                      <Message
                        message={state.planExpiryMessage}
                        fullWidth={state.shrinkMenu}
                        closeIcon={!checkIfPlanExpired(props.currentFirm)}
                        color='red'
                        onClick={() => moveToSubscriptionPage()}
                        onClose={() =>
                          setState((state) => ({
                            ...state,
                            planExpiryMessage: '',
                          }))
                        }
                      />
                    )}
                    {props.children}
                  </ErrorBoundary>
                </ScrollToBottom>
              </div>
            </div>
          </main>
        </div>
      </div>
      <TimeTrackingModal closeModal={closeTimeTrackingModal} />
      <div ref={infoFooterRef} className={`fixed md:relative`}>
        <MessageBar
          showInfoFooter={state.showInfoFooter}
          showHideInfoFooter={showHideInfoFooter}
          infoFooterHeight={state.infoFooterHeight}
        />
      </div>
      <div className='fixed md:relative'>
        <CustomMessageBar />
      </div>
      <div className='fixed md:relative'>
        <OfflineMessageBar />
      </div>
    </div>
  );
};

export default Dashboard;
