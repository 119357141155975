import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Fragment, useEffect, useState } from 'react';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

type Props = {
  className?: string;
  items: { [key: string]: any }[];
  label?: string;
  selected: { [key: string]: any };

  onChange: (item: any) => void;
};

export default function SelectMenu(props: Props) {
  const [selected, setSelected] = useState(props.selected);

  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);

  const onChange = (item: any) => {
    setSelected(props.selected);
    props.onChange(item);
  };

  return (
    <Listbox value={selected} onChange={onChange}>
      {({ open }: { open: any }) => (
        <>
          <Listbox.Label className='block text-sm font-medium text-gray-700'>
            {props.label}
          </Listbox.Label>
          <div className='mt-1 relative flex'>
            <Listbox.Button className='m-auto relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-pointer focus:outline-none focus:border-indigo-500 text-sm'>
              <span className='block truncate'>{selected?.name}</span>
              <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                <ChevronUpDownIcon
                  className='h-5 w-5 text-gray-400'
                  aria-hidden='true'
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options
                static
                className='absolute bottom-0 z-10 mt-1 min-w-max w-full bg-white shadow-lg max-h-28 rounded-md py-1 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm vertical-scroll'
              >
                {props.items.map((item, index) => (
                  <Listbox.Option
                    key={item.id || index}
                    className={({ active }: { active: any }) =>
                      classNames(
                        active ? 'text-white bg-indigo-600' : 'text-gray-900',
                        'cursor-pointer select-none relative py-2 pl-8 pr-4',
                      )
                    }
                    value={item}
                  >
                    {({ selected, active }: { selected: any; active: any }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate',
                          )}
                        >
                          {item.name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 left-0 flex items-center pl-1.5',
                            )}
                          >
                            <CheckIcon className='h-5 w-5' aria-hidden='true' />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
